import { configs } from "@/configs";
import { api } from "@/client/api";
import { parseDateTimeFromPrisma } from "@/utils";

import type { Facility, FacilityBooking } from "@/models";
import type { DateTime } from "luxon";

type CreateFacilityBookingInput = {
  facilityId: number;
  start: DateTime;
  end: DateTime;
};

export async function getFacilities(): Promise<Facility[]> {
  const response = await api.get(
    `${configs.apiUrl}/member/facilities?limit=100`
  );

  const { data } = response.data as { data: Facility[]; total: number };

  return parseDateTimeFromPrisma(data) as Facility[];
}

export async function getFacility(facilityId: number): Promise<Facility> {
  const response = await api.get(
    `${configs.apiUrl}/member/facilities/${facilityId}`
  );
  return parseDateTimeFromPrisma(response.data) as Facility;
}

export async function createFacilityBooking({
  facilityId,
  start,
  end,
}: CreateFacilityBookingInput) {
  const response = await api.post(
    `${configs.apiUrl}/member/facilities/${facilityId}/bookings`,
    { startedAt: start.toISO(), endedAt: end.toISO() }
  );
  return parseDateTimeFromPrisma(response.data) as FacilityBooking;
}
