import { Button } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export function BackToTopButton() {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <Button
      color="primary"
      variant="contained"
      sx={{
        borderRadius: 10,
        minWidth: 0,
        p: 1,
        position: "fixed",
        bottom: { xs: 48, md: 48 },
        right: 48,
        zIndex: 10,
      }}
      onClick={scrollToTop}
    >
      <ExpandLessIcon />
    </Button>
  );
}
