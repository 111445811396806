function BanIcon({ color }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="106"
      fill="none"
      viewBox="0 0 107 106"
    >
      <g clipPath="url(#clip0_14662_7730)">
        <path
          fill={color ?? "#3E3F80"}
          d="M53.5 0a53 53 0 1053 53 53.057 53.057 0 00-53-53zm0 8.833a43.941 43.941 0 0127.931 9.99L19.324 80.932A44.131 44.131 0 0153.5 8.833zm0 88.334a43.942 43.942 0 01-27.931-9.99l62.107-62.108A44.132 44.132 0 0153.5 97.167z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_14662_7730">
          <path
            fill="#fff"
            d="M0 0H106V106H0z"
            transform="translate(.5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default BanIcon;
