import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";

import {
  formatDay,
  formatProfile,
  formatTimeRange,
  sortDay,
} from "@/formatter";
import { CircularLoading } from "@/components/CircularLoading";
import { theme } from "@/theme";
import trainerDefaultImage from "@/assets/trainer-default-image.svg";
import { getOfficeDay } from "@/utils";

import type { Trainer } from "@/models";

type TrainerDetailCardProps = {
  data?: Trainer;
  isFetching: boolean;
};

export function TrainerDetailCard({
  data,
  isFetching,
}: TrainerDetailCardProps) {
  if (!data) return <></>;

  const {
    trainerProfileData: { avatarUrl, skills, workingDays, certificates },
    profile,
  } = data;

  const officeDay = getOfficeDay(workingDays).filter(({ enable }) => enable);

  return (
    <Card>
      {!isFetching ? (
        <CardContent sx={{ p: 0, pb: "0!important" }}>
          <Grid container spacing={{ xs: 2, md: 7 }}>
            <Grid
              item
              xs={12}
              md={4}
              height={{ xs: "547px", md: "auto" }}
              minHeight={{ xs: "unset", md: "547px" }}
            >
              <Box
                component="img"
                src={avatarUrl ?? trainerDefaultImage}
                height="100%"
                width="100%"
                sx={{ objectFit: "cover", objectPosition: "center" }}
              />
            </Grid>
            <Grid item xs={12} md={8} pr={{ xs: 0, md: 7 }}>
              <Stack mt={{ xs: 0, md: 5 }} px={{ xs: 2, md: 0 }}>
                <Typography sx={{ typography: { xs: "subtitle1", md: "h5" } }}>
                  {formatProfile(profile)}
                </Typography>
                <Stack direction="row" mt={2} gap={2}>
                  <MilitaryTechIcon sx={{ color: "text.secondary" }} />
                  <Stack>
                    {skills.map((skill) => (
                      <Typography
                        key={skill}
                        sx={{
                          typography: { xs: "subtitle2", md: "subtitle1" },
                        }}
                      >
                        {skill}
                      </Typography>
                    ))}
                  </Stack>
                </Stack>
                <Divider sx={{ my: { xs: 2, md: 3 } }} />
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Stack gap={{ xs: 0.5, md: 1 }}>
                      <Typography
                        color={`${theme.palette.text.secondary}!important`}
                        sx={{
                          typography: { xs: "subtitle2", md: "subtitle1" },
                        }}
                        mb={{ xs: 0.5, md: 1 }}
                      >
                        วันและเวลาเข้างาน
                      </Typography>
                      {officeDay
                        .sort(({ day: d1 }, { day: d2 }) => sortDay(d1, d2))
                        .map((day) => (
                          <Stack direction="row" key={day.day} gap={3}>
                            <Typography
                              color="text.secondary"
                              variant="body2"
                              width="80px"
                            >
                              {formatDay(day.day)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {day.timeRange &&
                                formatTimeRange(
                                  day.timeRange.start,
                                  day.timeRange.end
                                )}
                            </Typography>
                          </Stack>
                        ))}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6} mt={{ xs: 3, md: 0 }} mb={2}>
                    <Stack gap={{ xs: 1, md: 2 }}>
                      <Typography
                        color={`${theme.palette.text.secondary}!important`}
                        sx={{
                          typography: { xs: "subtitle2", md: "subtitle1" },
                        }}
                      >
                        ใบรับรอง
                      </Typography>
                      {certificates.length
                        ? certificates.map((certificate, index) => (
                            <Typography
                              key={`${index}-${certificate}`}
                              color="text.secondary"
                              variant="body2"
                              display="list-item"
                              ml={2}
                            >
                              {certificate}
                            </Typography>
                          ))
                        : "-"}
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <CircularLoading height={400} />
      )}
    </Card>
  );
}
