function LockIcon({ color }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="107"
      fill="none"
      viewBox="0 0 107 107"
    >
      <g fill={color ?? "#3E3F80"} clipPath="url(#clip0_14662_6670)">
        <path d="M84.416 37.706v-6.29a30.917 30.917 0 10-61.833 0v6.29a22.084 22.084 0 00-13.25 20.21v26.5A22.109 22.109 0 0031.416 106.5h44.167a22.11 22.11 0 0022.083-22.083v-26.5a22.084 22.084 0 00-13.25-20.211zm-53-6.29a22.083 22.083 0 0144.167 0v4.417H31.416v-4.416zm57.417 53a13.25 13.25 0 01-13.25 13.25H31.416a13.25 13.25 0 01-13.25-13.25v-26.5a13.25 13.25 0 0113.25-13.25h44.167a13.25 13.25 0 0113.25 13.25v26.5z"></path>
        <path d="M53.5 62.332a4.417 4.417 0 00-4.417 4.417v8.833a4.416 4.416 0 108.833 0v-8.833a4.417 4.417 0 00-4.416-4.417z"></path>
      </g>
      <defs>
        <clipPath id="clip0_14662_6670">
          <path
            fill="#fff"
            d="M0 0H106V106H0z"
            transform="translate(.5 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LockIcon;
