import { Box } from "@mui/material";

import type { BoxProps } from "@mui/material";

export function FitUPLogo({ color, ...props }: BoxProps) {
  return (
    <Box
      component="svg"
      width="40px"
      height="80px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 100"
      color={color}
      {...props}
    >
      <path
        d="m70.973 1.834-5.52 14.194c-.11.28-.309.515-.567.668L19.106 44.08a4.07 4.07 0 0 1-4.037.04L.73 36.105a1.348 1.348 0 0 1-.691-1.18v-11.68a1.354 1.354 0 0 1 .663-1.165 1.341 1.341 0 0 1 1.337-.015l13.113 7.325a4.063 4.063 0 0 0 4.036-.04L69.042.183a1.342 1.342 0 0 1 1.978.835c.069.271.052.556-.047.817ZM50.344 54.046l-14.57 8.525a1.348 1.348 0 0 0-.668 1.167v25.569a1.354 1.354 0 0 1-.668 1.166l-15.33 8.97a4.063 4.063 0 0 1-4.037.04L.731 91.469a1.345 1.345 0 0 1-.692-1.18V51.47a1.354 1.354 0 0 1 .663-1.164 1.342 1.342 0 0 1 1.337-.016l13.113 7.326a4.063 4.063 0 0 0 4.036-.041l35.6-21.38a1.342 1.342 0 0 1 1.991.815c.071.27.057.557-.04.82l-5.82 15.527c-.107.29-.31.533-.575.69Z"
        fill="currentColor"
      />
    </Box>
  );
}
