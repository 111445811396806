import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
} from "@mui/material";
import { useController } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import type {
  SelectProps as MuiSelectProps,
  InputLabelProps,
  FormControlProps,
} from "@mui/material";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

export type SelectProps<TValue> = MuiSelectProps<TValue> & {
  helperText?: string;
  FormControlProps?: FormControlProps;
  InputLabelProps?: InputLabelProps;
};

export function Select<TValue>({
  label,
  helperText,
  required,
  FormControlProps,
  InputLabelProps,
  value,
  ...props
}: SelectProps<TValue>) {
  const labelId = props.id ? `${props.id}-label` : undefined;

  return (
    <FormControl {...FormControlProps}>
      <InputLabel id={labelId} required={required} {...InputLabelProps}>
        {label}
      </InputLabel>
      <MuiSelect
        label={label}
        value={value ?? ("" as unknown as TValue)}
        {...props}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export type FormSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Pick<ControllerProps<TFieldValues, TName>, "name" | "control"> &
  Omit<SelectProps<TFieldValues[TName]>, "name" | "value">;

export function FormSelect<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  helperText,
  onChange,
  placeholder,
  ...props
}: FormSelectProps<TFieldValues, TName>) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <Select
      FormControlProps={{ error: fieldState.invalid }}
      inputRef={field.ref}
      onChange={(event, child) => {
        onChange?.(event, child);
        field.onChange(event);
      }}
      onBlur={field.onBlur}
      value={field.value}
      helperText={fieldState.error?.message ?? helperText}
      {...props}
    />
  );
}

export function SearchParamsSelect(
  props: Omit<SelectProps<string>, "value" | "onChange" | "name"> & {
    name: string;
  }
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = searchParams.get(props.name) ?? "0";
  const onChange: SelectProps<string>["onChange"] = (event) => {
    if (event.target.value) {
      searchParams.set(props.name, event.target.value);
    } else {
      searchParams.delete(props.name);
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  return <Select {...props} value={value} onChange={onChange} />;
}
