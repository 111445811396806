import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Box, CircularProgress } from "@mui/material";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
  Reducer,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import {
  signInWithEmailAndPassword,
  signOut as authSignOut,
  onAuthStateChanged,
  isFirebaseError,
  isAxiosError,
} from "@/lib/firebase/auth";
import {
  ApplicationException,
  TooManyRequestException,
  UserExpiredException,
  UserNoPermissionException,
  UserNotAuthenticatedException,
  UserNotFoundException,
  WrongPasswordException,
} from "@/exceptions";
import { api } from "@/client/api";
import { configs } from "@/configs";
import { AuthMemberResponsePayload, Branch, Theme } from "@/models";
import { parseDateTimeFromPrisma } from "@/utils";
import { sendLineLogin } from "@/services/line";
import liff from "@line/liff";

type User = {
  id: string;
  name: string;
  avatarURL: string;
  fitnessCenter: {
    id: number;
    logoUrl: string | null;
    branches: Branch[];
    theme: Theme;
    hasPermissionGroupControl: boolean;
  };
  homeBranchId: number;
  isActive: boolean;
};

type AuthenticationState = {
  isInitialized: boolean;
  user: User | null;
  isAuthenticated: boolean;
  error: Error | null;
  getToken: (() => Promise<string>) | null;
  loading: boolean;
  fitnessCentersId: number | null;
  isActive: boolean;
};

const { actions, reducer, getInitialState } = createSlice({
  name: "Authentication",
  reducers: {
    initialize: (state, { payload }: PayloadAction<User | null>) => {
      state.isInitialized = true;
      state.isAuthenticated = payload !== null;
      state.user = payload;
      state.fitnessCentersId = payload?.fitnessCenter.id ?? null;
      state.loading = false;
    },
    initializeError: (state, { payload }: PayloadAction<Error>) => {
      state.isInitialized = true;
      state.error = payload;
      state.loading = false;
    },
    signInSuccess: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
      state.fitnessCentersId = payload.fitnessCenter.id;
      state.isAuthenticated = true;
      state.error = null;
      state.loading = false;
    },
    signInFail: (state, { payload }: PayloadAction<Error>) => {
      state.user = null;
      state.fitnessCentersId = null;
      state.isAuthenticated = false;
      state.error = payload;
      state.loading = false;
    },
    signOut: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.fitnessCentersId = null;
      state.getToken = null;
      state.loading = false;
    },
    signInRequest: (state) => {
      state.loading = true;
    },
  },
  initialState: {
    isInitialized: false,
    user: null,
    fitnessCentersId: null,
    isAuthenticated: false,
    error: null,
    loading: false,
  } as AuthenticationState,
});

type Actions = typeof actions;
type AuthenticationActions = ReturnType<Actions[keyof Actions]>;
type AuthenticationDispatch = Dispatch<AuthenticationActions>;

const AuthenticationContext = createContext<
  readonly [AuthenticationState, AuthenticationDispatch] | null
>(null);

export function AuthenticationProvider({
  children,
}: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(
    reducer as Reducer<AuthenticationState, AuthenticationActions>,
    null,
    getInitialState
  );
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const branchId =
    searchParams.get("branchId") ?? state.user?.homeBranchId.toString();

  useEffect(() => {
    if (branchId) {
      localStorage.setItem("branchId", branchId);
    }
  }, [branchId]);

  const value = [state, dispatch] as const;

  useEffect(() => {
    if (state.error) {
      const isExpireError = state.error instanceof UserExpiredException;
      const errorMessage = isExpireError
        ? "ไม่สามารถเข้าสู่ระบบได้ในขณะนี้\nกรุณาติดต่อเจ้าหน้าที่"
        : state.error.message;

      void signOut(dispatch);
      console.error(state.error);
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  }, [state.error, enqueueSnackbar]);

  useEffect(() => {
    return onAuthStateChanged(async (firebaseUser) => {
      if (!firebaseUser) {
        localStorage.removeItem("branchId");
        return dispatch(actions.initialize(null));
      }

      try {
        const { profile, fitnessCenter, branch, membership } =
          await validateSignInUser();
        dispatch(
          actions.initialize({
            id: firebaseUser.uid,
            name: `${profile?.firstName ?? "Untitled"} ${
              profile?.lastName ?? ""
            }`,
            avatarURL:
              profile?.avatarUrl ?? "https://ui-avatars.com/api/?name=MB",
            fitnessCenter: {
              ...fitnessCenter,
              branches: fitnessCenter.branches.sort(
                (a, b) => a.createdAt.toMillis() - b.createdAt.toMillis()
              ),
            },
            homeBranchId: branch.id,
            isActive: !!membership,
          })
        );
        // Set theme color
        localStorage.setItem(
          "FITUP:primary.main",
          fitnessCenter.theme?.primaryColor ?? "#DE2826"
        );
        localStorage.setItem(
          "FITUP:accent.main",
          fitnessCenter.theme?.accentColor ?? "#A3000C"
        );
        localStorage.setItem(
          "FITUP:text.primary",
          fitnessCenter.theme?.textPrimaryColor ?? "#000000DE"
        );
        localStorage.setItem(
          "FITUP:text.secondary",
          fitnessCenter.theme?.textSecondaryColor ?? "#00000099"
        );
        localStorage.setItem(
          "FITUP:text.disabled",
          fitnessCenter.theme?.textDisabledColor ?? "#00000061"
        );
        localStorage.setItem(
          "FITUP:background.default",
          fitnessCenter.theme?.backgroundColor ?? "#F5F5F5"
        );
        localStorage.setItem(
          "FITUP:background.paper",
          fitnessCenter.theme?.paperColor ?? "#FFFFFF"
        );
      } catch (error) {
        if (isAxiosError(error)) {
          if (
            error.response &&
            (error.response.status === 403 || error.response.status === 406)
          ) {
            if (
              (error.response.data as { code: string; message: string }).code
            ) {
              return dispatch(actions.signInFail(new UserExpiredException()));
            }
            return dispatch(
              actions.signInFail(new UserNoPermissionException())
            );
          }
          if (error.response && error.response.status === 401) {
            return dispatch(
              actions.signInFail(new UserNotAuthenticatedException())
            );
          }
        }
        return dispatch(actions.signInFail(error as Error));
      }
    });
  }, []);

  return (
    <AuthenticationContext.Provider value={value}>
      {!state.isInitialized ? (
        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        children
      )}
    </AuthenticationContext.Provider>
  );
}

export function useAuthentication() {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error(
      "useAuthentication must be used inside <AuthenticationProvider />"
    );
  }

  return context;
}

export function useCurrentUser() {
  const [{ user }] = useAuthentication();

  if (!user) {
    throw new UserNotAuthenticatedException();
  }

  return user;
}

export async function signIn(
  dispatch: AuthenticationDispatch,
  email: string,
  password: string
) {
  dispatch(actions.signInRequest());
  try {
    await signInWithEmailAndPassword(email, password);
    await validateSignInUser();
    await sendLineLogin();
    liff.closeWindow();
  } catch (error) {
    if (!!isFirebaseError(error)) {
      return signInError(dispatch, error);
    }
  }
}

function signInError(
  dispatch: AuthenticationDispatch,
  error: ApplicationException
) {
  switch (error.code) {
    case "auth/wrong-password": {
      dispatch(actions.signInFail(new WrongPasswordException()));
      break;
    }
    case "auth/user-not-found": {
      dispatch(actions.signInFail(new UserNotFoundException()));
      break;
    }
    case "auth/too-many-requests": {
      dispatch(actions.signInFail(new TooManyRequestException()));
      break;
    }
    case "auth/no-permission": {
      dispatch(actions.signInFail(new UserNoPermissionException()));
      break;
    }
    default: {
      dispatch(actions.signInFail(error));
      break;
    }
  }
}

export async function signOut(dispatch: AuthenticationDispatch) {
  await authSignOut();
  dispatch(actions.signOut());
}

async function validateSignInUser(): Promise<AuthMemberResponsePayload> {
  const response = await api.post(`${configs.apiUrl}/auth/member-login`, {
    lineId: localStorage.getItem("userId"),
  });
  return parseDateTimeFromPrisma(response.data) as AuthMemberResponsePayload;
}
