import { Box, Grid, Stack, Typography } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { ConfirmDialog } from "@/components/ConfirmDialog";

import {
  formatBookingStatus,
  formatDate,
  formatTimeRange,
  formatBookingStatusColor,
} from "@/formatter";
import { cancelBooking } from "@/services/booking";
import { getBookingStatus, getAxiosErrorMessage } from "@/utils";

import type { Booking } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

const CANCEL_TRAINER_BEFORE_HOURS = 24;

export type BookingDialogProps = {
  open: boolean;
  data?: Booking;
  onClose: () => void;
};

export function CancelBookingDialog({
  open: isOpen,
  data,
  onClose,
}: BookingDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutate: cancel, isLoading } = useMutation(cancelBooking, {
    onSuccess: async () => {
      enqueueSnackbar("ยกเลิกการจองสำเร็จ", { variant: "success" });
      await queryClient.refetchQueries(["my-bookings"]);
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getAxiosErrorMessage(error), { variant: "error" });
    },
  });

  if (!data) return <></>;

  const {
    id,
    startedAt,
    endedAt,
    location,
    capacity,
    bookings,
    cancelBefore,
    scheduleTrainers,
    class: classModel,
    branch,
  } = data.schedule;
  const className = classModel?.name ?? "";

  const isTrainerBooking = !classModel;

  const durationInMinute = endedAt.diff(startedAt, ["minutes"]).minutes;
  const cancelBeforeTime = isTrainerBooking
    ? CANCEL_TRAINER_BEFORE_HOURS
    : startedAt.diff(cancelBefore, ["hours"]).hours;

  const activeBookings = bookings.filter(({ cancelledAt }) => !cancelledAt);
  const waitingBookings = bookings.filter(({ isWaiting }) => isWaiting);

  const bookedCount =
    activeBookings.length >= capacity ? capacity : activeBookings.length;

  const cancelBeforeLabel = `ยกเลิกการจอง\n(ก่อนเริ่ม${
    isTrainerBooking ? "เทรน" : "คลาส"
  })`;

  const onConfirm = () => {
    cancel({
      scheduleId: id,
      bookingId: data.id,
      type: classModel ? "class" : "trainer",
    });
  };

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title="คุณต้องการยกเลิกการจองหรือไม่"
      confirmMessage="ใช่"
      cancelMessage="ไม่ใช่"
      loading={isLoading}
      reverse={true}
    >
      <Stack>
        <Typography variant="body2">รายละเอียดการจอง</Typography>
        <Grid container alignItems="center">
          <DateRangeIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="body2" mb={3} mt={3}>
            {formatDate(startedAt)} {formatTimeRange(startedAt, endedAt)} (
            {durationInMinute}
            min)
          </Typography>
        </Grid>
        <Box
          display="grid"
          gridTemplateColumns="100px 1fr"
          rowGap={2}
          columnGap={3}
        >
          <Typography variant="subtitle2" color="text.disabled">
            ชื่อ{classModel ? "คลาส" : "เทรนเนอร์"}
          </Typography>
          <Typography noWrap variant="body2">
            {classModel
              ? className
              : `${scheduleTrainers?.[0].staff.profile.firstName ?? ""} ${
                  scheduleTrainers?.[0].staff.profile.lastName ?? ""
                }`}
          </Typography>
          {classModel && (
            <>
              <Typography variant="subtitle2" color="text.disabled">
                ผู้สอน
              </Typography>
              <Stack gap={1}>
                {scheduleTrainers?.map(({ staff }) => (
                  <Typography key={staff.id} variant="body2">
                    {staff.profile.firstName} {staff.profile.lastName} (
                    {staff.profile.nickname})
                  </Typography>
                ))}
              </Stack>
              <Typography variant="subtitle2" color="text.disabled">
                สถานที่
              </Typography>
              <Typography variant="body2">{location}</Typography>
            </>
          )}
          <Typography variant="subtitle2" color="text.disabled">
            สาขา
          </Typography>
          <Typography variant="body2">{branch.name}</Typography>
          {classModel && (
            <>
              <Typography variant="subtitle2" color="text.disabled">
                ผู้เข้าร่วม
              </Typography>
              <Typography variant="body2">
                {bookedCount} / {capacity} คน
              </Typography>
              {!!waitingBookings.length && (
                <>
                  <Typography variant="subtitle2" color="text.disabled">
                    รอคิว
                  </Typography>
                  <Typography variant="body2">
                    {waitingBookings.length} คน
                  </Typography>
                </>
              )}
            </>
          )}
          <Typography variant="subtitle2" color="text.disabled">
            สถานะ
          </Typography>
          <Typography
            variant="body2"
            color={
              classModel
                ? formatBookingStatusColor(getBookingStatus(data))
                : "success.main"
            }
          >
            {formatBookingStatus(getBookingStatus(data))}
          </Typography>
          <Box>
            <Typography
              variant="subtitle2"
              color="text.disabled"
              whiteSpace="pre-line"
            >
              {cancelBeforeLabel}
            </Typography>
          </Box>
          <Typography variant="body2">{cancelBeforeTime} ชั่วโมง</Typography>
        </Box>
      </Stack>
    </ConfirmDialog>
  );
}
