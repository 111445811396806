import { Stack, StackProps, Typography } from "@mui/material";

import { DividerTitle } from "@/components/DividerTitle";

type TitleProps = { title: string } & StackProps;

export function Title({ title, ...props }: TitleProps) {
  return (
    <Stack alignItems="center" gap={1} {...props}>
      <Typography variant="h1" sx={{ typography: { xs: "h6", md: "h4" } }}>
        {title}
      </Typography>
      <DividerTitle />
    </Stack>
  );
}
