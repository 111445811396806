import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { useNavigate } from "react-router-dom";

import { formatProfile } from "@/formatter";
import trainerDefaultImage from "@/assets/trainer-default-image.svg";

import type { MouseEventHandler } from "react";
import type { Trainer } from "@/models";

type PTCardProps = {
  data: Trainer;
  branchId: number;
};

export function TrainerCard({ data, branchId }: PTCardProps) {
  const {
    id,
    trainerProfileData: { avatarUrl, skills },
    profile,
  } = data;
  const navigate = useNavigate();

  const onClick: MouseEventHandler<HTMLButtonElement> = () => {
    navigate(`${id.toString()}?branchId=${branchId}`);
  };

  return (
    <Card>
      <CardMedia
        component="img"
        image={avatarUrl ?? trainerDefaultImage}
        sx={{ height: { xs: "240px", md: "372px" } }}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: { xs: "calc(100% - 240px)", md: "calc(100% - 372px)" },
        }}
      >
        <Typography sx={{ typography: { xs: "subtitle2", md: "subtitle1" } }}>
          {formatProfile(profile, true)}
        </Typography>
        <Stack direction="row" mt={1} gap={0.5} mb={{ xs: 2, md: 3.5 }}>
          <MilitaryTechIcon sx={{ color: "text.secondary" }} />
          <Stack gap={0.5}>
            {skills.map((skill) => (
              <Typography key={skill} variant="caption">
                {skill}
              </Typography>
            ))}
          </Stack>
        </Stack>
        <Button
          size="medium"
          variant="contained"
          fullWidth
          sx={{ mt: "auto" }}
          onClick={onClick}
        >
          รายละเอียด
        </Button>
      </CardContent>
    </Card>
  );
}
