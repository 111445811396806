import { useSnackbar } from "notistack";
import { useMutation } from "@tanstack/react-query";
import { Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ErrorIcon from "@mui/icons-material/Error";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { formatDate, formatNumber, formatTimeRange } from "@/formatter";
import { getAxiosErrorMessage, mapOptional } from "@/utils";
import { createFacilityBooking } from "@/services/facility";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import type { DateTime } from "luxon";
import type { Facility } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

type FacilityBookingDialogProps = {
  open: boolean;
  data: Facility;
  start: DateTime;
  end: DateTime;
  onClose: () => void;
  selectedBranch: { id: number; name: string };
};

export function FacilityBookingDialog({
  open: isOpen,
  data,
  start,
  end,
  onClose,
  selectedBranch,
}: FacilityBookingDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { isActive } = useCurrentUser();

  const { id, name, guestFee, memberFee } = data;
  const hasFee = !!guestFee || !!memberFee;

  const { mutate: add, isLoading } = useMutation(createFacilityBooking, {
    onSuccess: () => {
      enqueueSnackbar("การจองของคุณสำเร็จ", { variant: "success" });
      onClose();
      navigate(-1);
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getAxiosErrorMessage(error), { variant: "error" });
    },
  });

  const onConfirm = () => add({ start, end, facilityId: id });

  const durationInMinute = end.diff(start, ["minutes"]).minutes;

  const fee = isActive ? memberFee : guestFee;

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title="ยืนยันการจอง"
      confirmMessage="ยืนยัน"
      loading={isLoading}
    >
      <Stack>
        <Typography variant="body1">รายละเอียดการจอง</Typography>
        <Grid container alignItems="center">
          <DateRangeIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="body1" mb={3} mt={3}>
            {formatDate(start)} {formatTimeRange(start, end)} (
            {durationInMinute}
            min)
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant="body2" color="text.disabled">
              สิ่งอำนวยความสะดวก
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{name}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="text.disabled">
              สาขา
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{selectedBranch.name}</Typography>
          </Grid>
          {hasFee && (
            <>
              <Grid item xs={4}>
                <Typography variant="body2" color="text.disabled">
                  ค่าบริการเพิ่มเติม
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">
                  {mapOptional(fee, formatNumber) ?? "-"} บาท
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Stack direction="row" gap={2}>
              <ErrorIcon color="error" />
              <Typography variant="body2" color="error.main">
                เมื่อทำการจองแล้ว หากต้องการยกเลิกการจอง
                จะต้องยกเลิกการจองล่วงหน้าอย่างน้อย 24 ชั่วโมง
              </Typography>
            </Stack>
          </Grid>
          {hasFee && (
            <Grid item xs={12}>
              <Stack direction="row" gap={2}>
                <ErrorIcon color="error" />
                <Typography variant="body2" color="error.main">
                  กรุณาชำระเงินและโอนสลิปผ่านช่องทาง LINE Official Account
                  เพื่อยืนยันการจอง
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Stack>
    </ConfirmDialog>
  );
}
