import {
  Button,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import {
  formatDate,
  formatFacilityBookingStatus,
  formatFacilityBookingStatusColor,
  formatFacilityType,
  formatNumber,
  formatTimeRange,
} from "@/formatter";
import { mapOptional } from "@/utils";
import { FacilityBookingStatus } from "@/models";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { theme } from "@/theme";

import type { FacilityBooking } from "@/models";
import type { MouseEventHandler } from "react";

export type FacilityBookingCardProps = {
  data: FacilityBooking;
};

export function FacilityBookingCard({ data }: FacilityBookingCardProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isActive } = useCurrentUser();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const {
    id,
    facilities: { name, type, memberFee, guestFee },
    branchs,
    startedAt,
    endedAt,
    status,
    paymentSlipUrl,
  } = data;

  const isPending = status === FacilityBookingStatus.Pending;
  const isWaitingForApprove = isPending && !!paymentSlipUrl;
  const canUploadImage = [
    FacilityBookingStatus.Pending,
    FacilityBookingStatus.Rejected,
  ].includes(status);
  const hasFee = !!memberFee || !!guestFee;
  const fee = isActive ? memberFee : guestFee;

  const onClickUpload: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "upload-booking");
    searchParams.set("id", id.toString());
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <CardContent>
      <Grid container gap={2} mt={4} mb={4}>
        <Grid
          item
          md={2}
          sx={{
            flexDirection: { xs: "row", md: "column" },
            display: { xs: "flex", md: "block" },
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{formatDate(startedAt)}</Typography>
          <Typography
            variant="body1"
            color="text.disabled"
            sx={{
              marginTop: { xs: 0.5, md: 0 },
              marginLeft: { xs: 2, md: 0 },
            }}
          >
            {formatTimeRange(startedAt, endedAt)}
          </Typography>
          {isMobile && <CancelButton data={data} />}
        </Grid>
        <Grid
          item
          md={7}
          pr={2}
          textAlign="left"
          sx={{
            borderLeft: { md: "solid 1px lightgray", xs: "none" },
            pl: { xs: 0, md: 10 },
            width: "100%",
          }}
        >
          <Typography
            noWrap
            variant="h6"
            width={{ xs: "calc(100vw - 80px)", md: "unset" }}
          >
            {name}
          </Typography>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={3}>
              <Typography variant="subtitle2" color="text.disabled">
                ประเภท
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">
                {formatFacilityType(type)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" color="text.disabled">
                สาขา
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{branchs.name}</Typography>
            </Grid>
            {hasFee && (
              <>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" color="text.disabled">
                    ค่าบริการเพิ่มเติม
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">
                    {mapOptional(fee, formatNumber) ?? "-"} บาท
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={3}>
              <Typography variant="subtitle2" color="text.disabled">
                สถานะ
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body2"
                color={
                  isWaitingForApprove
                    ? "info.main"
                    : formatFacilityBookingStatusColor(status)
                }
              >
                {isWaitingForApprove
                  ? "แนบหลักฐานแล้ว รอยืนยันการจอง"
                  : formatFacilityBookingStatus(status)}
              </Typography>
            </Grid>
            {canUploadImage && (
              <Grid item xs={12}>
                <Button onClick={onClickUpload}>เพิ่มหลักฐานการจอง</Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid
            item
            md={2}
            xs={12}
            sx={{
              pl: { xs: "20%", md: 0 },
              pr: { xs: "20%", md: 0 },
            }}
          >
            <CancelButton data={data} />
          </Grid>
        )}
      </Grid>
    </CardContent>
  );
}

type CancelButtonProps = {
  data: FacilityBooking;
};

function CancelButton({ data: { id, status } }: CancelButtonProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const cancelBooking = [
    FacilityBookingStatus.Pending,
    FacilityBookingStatus.Rejected,
  ].includes(status);

  const onClickCancel: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "cancel-booking");
    searchParams.set("id", id.toString());
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <Button
      color="error"
      variant="outlined"
      onClick={onClickCancel}
      disabled={!cancelBooking}
    >
      ยกเลิก
    </Button>
  );
}
