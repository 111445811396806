import { Box, Stack, Tab, Tabs, TabsProps } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Title } from "@/components/Title";
import { usePageBottom } from "@/hooks/usePageBottom";
import { BackToTopButton } from "@/components/BackToTopButton";

export function BookingListPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const reachedBottom = usePageBottom();
  const path = location?.pathname ?? "/my-bookings";

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  const title = "การจองของฉัน";
  return (
    <Box sx={{ px: { xs: 3, md: 5 } }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Stack alignItems="center" gap={1} sx={{ my: { xs: 4, md: 6 } }}>
        {reachedBottom && <BackToTopButton />}
        <Title title={title} />
        <Tabs value={path} sx={{ mt: 7.5 }} onChange={onChangeTab}>
          <Tab
            label="กิจกรรมที่จะมาถึง"
            sx={{ width: 140 }}
            value="/my-bookings"
          />
          <Tab
            label="สิ่งอำนวยความสะดวก"
            sx={{ width: 164 }}
            value="/my-bookings/facility"
          />
          <Tab
            label="ประวัติการจอง"
            sx={{ width: 140 }}
            value="/my-bookings/history"
          />
        </Tabs>
        <Outlet />
      </Stack>
    </Box>
  );
}
