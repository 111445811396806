import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { formatDateRange, formatDurationISO } from "@/formatter";
import { PurchaseType } from "@/models";
import { mapOptional } from "@/utils";

import type { Membership, PersonalTrainingQuota } from "@/models";
import type { ReactNode } from "react";

type ProductContainerProps =
  | { type: PurchaseType.Membership; data: Membership[] }
  | {
      type: PurchaseType.ProductPT;
      data: PersonalTrainingQuota[];
    };

type GetProductFieldsInput =
  | { type: PurchaseType.Membership; data: Membership | null }
  | {
      type: PurchaseType.ProductPT;
      data: PersonalTrainingQuota | null;
    };

function getProductFields({ type, data }: GetProductFieldsInput): ReactNode {
  const durationIso =
    type === PurchaseType.Membership
      ? data?.product.productMembership.durationIso
      : data?.product.productPersonalTraining.durationIso;

  const fields = [
    {
      label: "ชื่อแพ็กเกจ",
      value: data?.product?.name ?? "-",
    },
    {
      label: "ระยะเวลา",
      value: mapOptional(durationIso, formatDurationISO) ?? "-",
    },
    {
      label: "วันเริ่มต้น - สิ้นสุด",
      value: data ? formatDateRange(data.startedAt, data.endedAt) : "-",
    },
  ];

  return fields.map(({ label, value }) => (
    <Fragment key={label + value}>
      <Typography variant="subtitle2" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {value}
      </Typography>
    </Fragment>
  ));
}

function Paginator({
  onClickNext,
  onClickPrevious,
  isNextDisabled,
  isPreviousDisabled,
  currentPage,
}: {
  onClickNext: () => void;
  onClickPrevious: () => void;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
  currentPage: string;
}) {
  return (
    <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
      <IconButton
        aria-label="previous"
        disabled={isPreviousDisabled}
        onClick={onClickPrevious}
      >
        <ChevronLeftIcon
          fontSize="inherit"
          sx={{ color: (theme) => theme.palette.grey[500] }}
        />
      </IconButton>
      <Typography variant="subtitle1">{currentPage}</Typography>
      <IconButton
        aria-label="next"
        disabled={isNextDisabled}
        onClick={onClickNext}
      >
        <ChevronRightIcon
          fontSize="inherit"
          sx={{ color: (theme) => theme.palette.grey[500] }}
        />
      </IconButton>
    </Stack>
  );
}

export function ProductContainer({ type, data }: ProductContainerProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const pages = currentIndex + 1;
  const total = data.length || 1;

  function onClickBranch() {
    searchParams.set("dialog", type);
    setSearchParams(searchParams, { replace: true });
  }

  const dialog = searchParams.get("dialog");
  const branchNames =
    data[currentIndex]?.product?.productBranches.map(
      ({ branch }) => branch.name
    ) ?? [];

  const productBranchDialog = { open: dialog === type, branchNames };
  const productName =
    type === PurchaseType.Membership ? "แพ็กเกจสมาชิก" : "แพ็กเกจเทรนเนอร์";

  return (
    <Stack gap={2}>
      <Typography variant="subtitle1">{productName}</Typography>
      <Box display="grid" gridTemplateColumns="124px 1fr" rowGap={1}>
        {getProductFields(
          type === PurchaseType.Membership
            ? {
                type: PurchaseType.Membership,
                data: data[currentIndex] as Membership | null,
              }
            : {
                type: PurchaseType.ProductPT,
                data: data[currentIndex] as PersonalTrainingQuota | null,
              }
        )}
      </Box>
      {branchNames?.length > 1 ? (
        <Button
          variant="text"
          size="medium"
          sx={{ width: "fit-content", alignSelf: "center" }}
          onClick={onClickBranch}
        >
          สาขาที่เข้าใช้บริการได้
        </Button>
      ) : null}

      <Paginator
        onClickNext={() => {
          setCurrentIndex(currentIndex + 1);
        }}
        onClickPrevious={() => {
          setCurrentIndex(currentIndex - 1);
        }}
        isNextDisabled={pages >= total}
        isPreviousDisabled={currentIndex === 0}
        currentPage={`${pages}/${total}`}
      />
      <ProductBranchDialog {...productBranchDialog} />
    </Stack>
  );
}

export type ProductBranchDialogProps = {
  open: boolean;
  branchNames: string[];
};

function ProductBranchDialog({
  open: isOpen,
  branchNames,
}: ProductBranchDialogProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  function onClose() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: 444 } }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">สาขาที่เข้าใช้บริการได้</Typography>
          <IconButton
            size="large"
            disableRipple
            onClick={onClose}
            sx={{ color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 3, pt: "24px !important", mb: 5 }}>
        {branchNames?.map((name, index) => (
          <Typography key={name} variant="body1">
            <li key={`${index}-${name}`}>{name}</li>
          </Typography>
        ))}
      </DialogContent>
    </Dialog>
  );
}
