import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { useRequireParams } from "@/utils";
import { Title } from "@/components/Title";
import { getTrainer } from "@/services/trainer";
import { TrainerDetailCard } from "@/components/TrainerDetailCard";
import { usePageBottom } from "@/hooks/usePageBottom";
import { BackToTopButton } from "@/components/BackToTopButton";

import {
  TrainerBookingCard,
  useTrainerBookingCardForm,
} from "./TrainerBookingCard";

const QUERY_KEY = "getTrainer";

export function TrainerDetailPage() {
  const { id } = useRequireParams(["id"]);
  const reachedBottom = usePageBottom();

  const title = "เลือกวันและเวลา";

  const { control } = useTrainerBookingCardForm();

  const { data: raw, isFetching } = useQuery([QUERY_KEY], () =>
    getTrainer(+id)
  );

  return (
    <Stack
      mx={{ xs: 3, md: "auto" }}
      gap={{ md: 5, xs: 4 }}
      maxWidth="1024px"
      mb={5}
    >
      <Title title={title} my={{ xs: 4, md: 7 }} />
      <TrainerBookingCard control={control} data={raw} />
      <TrainerDetailCard data={raw} isFetching={isFetching} />
      {reachedBottom && <BackToTopButton />}
    </Stack>
  );
}
