import { Box } from "@mui/material";
import { Carousel as ReactCarousal } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import type { SxProps } from "@mui/material";
import { configs } from "@/configs";

type CarouselProps = {
  imageUrls: string[];
  imgSx?: SxProps;
};

export function Carousel({ imageUrls, imgSx }: CarouselProps) {
  return (
    <ReactCarousal
      autoPlay={imageUrls.length > 1}
      infiniteLoop
      showArrows={false}
      showStatus={false}
      showThumbs={false}
    >
      {imageUrls.map((imageUrl, index) => (
        <Box
          key={index}
          component="img"
          src={imageUrl ?? configs.defaultImageURL}
          maxHeight={{ xs: 260, md: 480 }}
          width={"100%"}
          sx={{ objectFit: "cover", objectPosition: "center", ...imgSx }}
        />
      ))}
    </ReactCarousal>
  );
}
