import { configs } from "@/configs";
import { api } from "@/client/api";
import {
  parseDateTimeFromPrisma,
  parseISOToPrisma,
  parseURLSearchParams,
} from "@/utils";

import type { Booking, Class, Schedule } from "@/models";
import { DateTime } from "luxon";

export type CreateClassBookingInput = {
  scheduleId: number;
};
export type GetClassSchedulesInput = {
  classId?: number;
  start: DateTime;
  end?: DateTime;
};

export async function getClasses(params = ""): Promise<Class[]> {
  const response = await api.get(`${configs.apiUrl}/member/classes?${params}`);
  return parseDateTimeFromPrisma(response.data) as Class[];
}

export async function getClass(id: string): Promise<Class> {
  const response = await api.get(`${configs.apiUrl}/member/classes/${id}`);
  return parseDateTimeFromPrisma(response.data) as Class;
}

export async function getSchedules(
  input: GetClassSchedulesInput
): Promise<Schedule[]> {
  const params = parseISOToPrisma(input) as Record<string, string>;
  const response = await api.get(
    `${configs.apiUrl}/member/schedules?${parseURLSearchParams(params)}`
  );
  return parseDateTimeFromPrisma(response.data) as Schedule[];
}

export async function createClassBooking({
  scheduleId,
}: CreateClassBookingInput): Promise<Booking> {
  const response = await api.post(
    `${configs.apiUrl}/member/schedules/${scheduleId}/booking`
  );

  return parseDateTimeFromPrisma(response.data) as Booking;
}
