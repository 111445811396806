import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { useRequireParams } from "@/utils";
import { Title } from "@/components/Title";
import { usePageBottom } from "@/hooks/usePageBottom";
import { BackToTopButton } from "@/components/BackToTopButton";
import { getFacility } from "@/services/facility";

import {
  FacilityBookingCard,
  useFacilityBookingCardForm,
} from "./FacilityBookingCard";
import { FacilityDetailCard } from "./FacilityDetailCard";

const QUERY_KEY = "getFacility";

export function FacilityDetailPage() {
  const { id } = useRequireParams(["id"]);
  const reachedBottom = usePageBottom();

  const title = "เลือกวันและเวลา";

  const { control } = useFacilityBookingCardForm();

  const { data: raw, isFetching } = useQuery([QUERY_KEY], () =>
    getFacility(+id)
  );

  return (
    <Stack
      mx={{ xs: 3, md: "auto" }}
      gap={{ md: 5, xs: 4 }}
      maxWidth="1024px"
      mb={5}
    >
      <Title title={title} my={{ xs: 4, md: 7 }} />
      <FacilityBookingCard control={control} data={raw} />
      <FacilityDetailCard data={raw} isFetching={isFetching} />
      {reachedBottom && <BackToTopButton />}
    </Stack>
  );
}
