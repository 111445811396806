import { MenuItem } from "@mui/material";

import { Select } from "@/components/Select";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import type { SelectProps } from "@/components/Select";

export function AllBranchSelect({
  value,
  onChange,
  ...props
}: SelectProps<number>) {
  const {
    fitnessCenter: { branches },
  } = useCurrentUser();

  return (
    <Select
      label="สาขา"
      name="branch"
      FormControlProps={{ fullWidth: true }}
      value={value}
      onChange={(event, child) => onChange?.(event, child)}
      {...props}
    >
      {branches.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
}
