import {
  Button,
  Card,
  Dialog,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useSearchParams } from "react-router-dom";

import { DividerTitle } from "@/components/DividerTitle";
import { CircularLoading } from "@/components/CircularLoading";
import { Carousel } from "@/components/Carousel";
import { BookingDialog } from "@/components/BookingDialog";
import { getClass, getSchedules } from "@/services/class";
import { EmptyResultIcon } from "@/components/EmptyResultIcon";
import { BackToTopButton } from "@/components/BackToTopButton";
import { usePageBottom } from "@/hooks/usePageBottom";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { getBranchesByClass } from "@/services/branch";
import { Select } from "@/components/Select";
import { useRequireParams } from "@/utils";
import { now } from "@/lib/dateTime";
import { configs } from "@/configs";
import { theme } from "@/theme";

import { ClassSchedule } from "./ClassShedule";

import type { SelectChangeEvent } from "@mui/material";

const QUERY_KEY = "class";
const QUERY_SCHEDULES_KEY = "schedules";
const QUERY_BRANCHES_KEY = "branchesByClass";

export function ClassDetailPage() {
  const [dialogProps, setDialogProps] = useState({
    dialog: "",
    scheduleId: "",
  });
  const { id } = useRequireParams(["id"]);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isOpen, setOpen] = useState(false);
  const reachedBottom = usePageBottom();

  const { homeBranchId } = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();

  const branchId = searchParams.get("branchId") ?? homeBranchId.toString();

  const { dialog, scheduleId } = dialogProps;

  const playerHeight = isMobile ? 174 : 292;

  const date = now();

  const { data: raw, isFetching: isFetchingClass } = useQuery([QUERY_KEY], () =>
    getClass(id)
  );
  const { data: schedulesRaw, isFetching: isFetchingSchedules } = useQuery(
    [QUERY_SCHEDULES_KEY, id, branchId, date.toISODate()],
    () => getSchedules({ classId: +id, start: date })
  );
  const { data: branchesRaw, isFetching: isFetchingBranches } = useQuery(
    [QUERY_BRANCHES_KEY, id],
    () => getBranchesByClass(+id)
  );

  const isLoaded = !isFetchingClass && !isFetchingBranches;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = raw?.name;
  const description = raw?.description;
  const classTitle = "เลือกตารางเวลา";

  const imageUrls = raw?.thumbnailUrls ?? [];

  const carousel = {
    imageUrls: imageUrls.length ? imageUrls : [configs.defaultImageURL],
  };

  const schedules = schedulesRaw ?? [];

  const onCloseDialog = () => setDialogProps({ dialog: "", scheduleId: "" });

  const data = schedules.find(
    (schedule) => schedule.id.toString() === scheduleId
  );

  const bookingDialog = {
    open: dialog === "class-booking",
    data,
    onClose: onCloseDialog,
  };

  const onChangeBranch = (event: SelectChangeEvent<number>) => {
    searchParams.set("branchId", event.target.value.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const isShowBranchSelect = (branchesRaw ?? []).length > 1;

  return isLoaded ? (
    <Stack>
      <Grid container bgcolor="background.paper">
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            p: { md: 10, xs: 3 },
            textAlign: { md: "left", xs: "-webkit-center" },
          }}
          order={{ xs: 2, md: 1 }}
        >
          <Typography
            sx={{
              typography: { md: "h3", xs: "h6" },
              wordBreak: "break-word",
            }}
            mb={{ xs: 0.5, md: 3 }}
          >
            {title}
          </Typography>
          <DividerTitle />
          <Typography color="text.secondary" mb={3} mt={3}>
            {description}
          </Typography>
          {raw?.youtubeUrl && isMobile ? (
            <Stack gap={0.5} textAlign="left">
              <Typography variant="subtitle1" color="text.secondary">
                วิดีโอ
              </Typography>
              <ReactPlayer
                url={raw.youtubeUrl}
                controls={true}
                width="100%"
                height={playerHeight}
              />
            </Stack>
          ) : (
            raw?.youtubeUrl && (
              <Button
                color="inherit"
                variant="contained"
                startIcon={<VideocamIcon />}
                sx={{ backgroundColor: "background.default" }}
                onClick={() => setOpen(true)}
              >
                ดูวิดีโอตัวอย่าง
              </Button>
            )
          )}
        </Grid>
        <Grid
          item
          height={{ xs: 260, md: 480 }}
          md={6}
          xs={12}
          order={{ xs: 1, md: 1 }}
        >
          <Carousel {...carousel} />
        </Grid>
      </Grid>
      <Stack
        alignItems="center"
        mt={{ xs: 4, md: 8 }}
        mb={10}
        gap={{ xs: 4, md: 7 }}
        px={3}
      >
        <Stack alignItems="center">
          <Typography
            sx={{ typography: { md: "h4", xs: "h6" } }}
            mb={{ xs: 0.5, md: 1 }}
          >
            {classTitle}
          </Typography>
          <DividerTitle />
        </Stack>
        {isShowBranchSelect && (
          <Stack width="100%" alignItems="center" px={{ xs: 4.5, md: 0 }}>
            <Select
              label="สาขา"
              name="branch"
              value={+branchId}
              onChange={onChangeBranch}
              FormControlProps={{
                sx: { width: "100%", maxWidth: 428 },
              }}
            >
              {branchesRaw?.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        )}
        <Card
          sx={{
            width: "100%",
            maxWidth: 980,
            p: { xs: 2, md: 5 },
          }}
        >
          {!isFetchingSchedules ? (
            <Stack gap={{ xs: 4, md: 3 }}>
              {schedules.length === 0 && <EmptyResultIcon variant="schedule" />}
              {schedules.map((item, index) => (
                <Stack key={item.id} px={{ md: 5 }} gap={{ xs: 4, md: 3 }}>
                  <ClassSchedule data={item} setDialogProps={setDialogProps} />
                  {schedules.length !== index + 1 && <Divider />}
                </Stack>
              ))}
            </Stack>
          ) : (
            <CircularLoading height={200} />
          )}
        </Card>
      </Stack>
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <ReactPlayer
          url={raw?.youtubeUrl}
          controls={true}
          width="100%"
          playing={true}
        />
      </Dialog>
      <BookingDialog {...bookingDialog} />
      {reachedBottom && <BackToTopButton />}
    </Stack>
  ) : (
    <CircularLoading height="100vh" />
  );
}
