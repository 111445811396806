import { configs } from "@/configs";
import { api } from "@/client/api";
import { parseDateTimeFromPrisma } from "@/utils";

import type { Booking, FacilityBooking } from "@/models";

type GetBookings = {
  data: Booking[];
  total: number;
};

type GetFacilityBookings = {
  data: FacilityBooking[];
  total: number;
};

type CancelBooking = {
  bookingId: number;
  scheduleId: number;
  type: string;
};

type UpdateFacilityBookingSlipInput = {
  bookingId: number;
  imageUrl: string;
};

export async function getUpcomingBookings(params = ""): Promise<GetBookings> {
  const response = await api.get(
    `${configs.apiUrl}/member/bookings/upcoming?limit=${configs.limitPerPage}&${params}`
  );
  return parseDateTimeFromPrisma(response.data) as GetBookings;
}

export async function getHistoryBookings(params = ""): Promise<GetBookings> {
  const response = await api.get(
    `${configs.apiUrl}/member/bookings/history?limit=${configs.limitPerPage}&${params}`
  );
  return parseDateTimeFromPrisma(response.data) as GetBookings;
}

export async function getFacilityBookings(
  params = ""
): Promise<GetFacilityBookings> {
  const response = await api.get(
    `${configs.apiUrl}/member/bookings/facility?limit=${configs.limitPerPage}&${params}`
  );
  return parseDateTimeFromPrisma(response.data) as GetFacilityBookings;
}

export async function cancelBooking(data: CancelBooking): Promise<string> {
  const response = await api.patch(
    `${configs.apiUrl}/member/bookings/cancel-booking`,
    data
  );
  return response.data as string;
}

export async function cancelFacilityBooking(id: number): Promise<string> {
  const response = await api.delete(
    `${configs.apiUrl}/member/facilities/bookings/${id}`
  );
  return response.data as string;
}

export async function updateFacilityBookingSlip({
  bookingId,
  imageUrl,
}: UpdateFacilityBookingSlipInput): Promise<string> {
  const response = await api.patch(
    `${configs.apiUrl}/member/facilities/bookings/${bookingId}`,
    { imageUrl }
  );
  return response.data as string;
}
