import { Button, Typography, Stack, SxProps } from "@mui/material";

import { formatDate, formatTime } from "@/formatter";
import { now } from "@/lib/dateTime";

import type { ButtonProps } from "@mui/material";
import type { MouseEventHandler } from "react";
import type { Schedule } from "@/models";

export type BookingButtonProps = {
  data: Schedule;
  onClick: MouseEventHandler;
};

export function BookingButton({
  data,
  onClick,
  TypographySx,
  ...props
}: BookingButtonProps &
  Omit<ButtonProps, "onClick"> & { TypographySx?: SxProps }) {
  const { joinedAt, isBooked } = data;
  const canJoin = joinedAt ? !isBooked && now() > joinedAt : !isBooked;
  const canBooking = joinedAt && now() < joinedAt;
  return (
    <Stack width="100%" alignItems="center">
      <Button
        variant="contained"
        disabled={!canJoin}
        fullWidth
        onClick={onClick}
        {...props}
      >
        {canBooking ? "เปิดจองเร็วๆนี้" : isBooked ? "จองแล้ว" : "จอง"}
      </Button>
      {canBooking && (
        <Typography
          color="primary.main"
          mt={1}
          fontSize="0.75rem"
          sx={TypographySx}
        >
          *เปิดจองวันที่ {formatDate(joinedAt)} เวลา {formatTime(joinedAt)}
        </Typography>
      )}
    </Stack>
  );
}
