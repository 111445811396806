import {
  BookingStatus,
  Day,
  DayString,
  FacilityBookingStatus,
  FacilityType,
  Member,
  Profile,
  Staff,
} from "@/models";
import { fromDurationISO } from "@/lib/duration";

import type { DateTime, Duration } from "luxon";

export function formatFullDate(date: DateTime) {
  return date.setLocale("th-TH").toFormat("EEEEที่ d MMM yyyy");
}

export function formatDate(date: DateTime) {
  return date.toFormat("d/M/yyyy");
}

export function formatTime(date: DateTime) {
  return date.toFormat("H:mm");
}

export function formatTimeRange(start: DateTime, end: DateTime) {
  return `${formatTime(start)} - ${formatTime(end)}`;
}

export function formatDateRange(start: DateTime, end: DateTime) {
  return `${formatDate(start)} - ${formatDate(end)}`;
}

export function formatDateTime(date: DateTime) {
  return `${formatDate(date)} ${formatTime(date)}`;
}

export function formatProfile(profile: Profile, isShortLastName = false) {
  const { firstName, lastName, nickname } = profile;

  const newLastName = isShortLastName ? `${lastName[0]}.` : lastName;
  return `${firstName} ${newLastName} (${nickname})`;
}

export function formatProfiles(items: Pick<Staff | Member, "profile">[]) {
  return items.map(({ profile }) => formatProfile(profile)).join(",");
}

const bookingStatusMapper = {
  [BookingStatus.Booked]: "จองสำเร็จ",
  [BookingStatus.Waiting]: "รอคิว",
  [BookingStatus.Cancelled]: "ยกเลิก",
  [BookingStatus.Pending]: "รอการยืนยัน",
  [BookingStatus.Absent]: "ไม่เข้าร่วม",
  [BookingStatus.Attended]: "เข้าร่วม",
};
export function formatBookingStatus(status: BookingStatus) {
  return bookingStatusMapper[status];
}

const bookingStatusColorMapper = {
  [BookingStatus.Booked]: "success.main",
  [BookingStatus.Waiting]: "warning.main",
  [BookingStatus.Cancelled]: "text.disabled",
  [BookingStatus.Pending]: "warning.main",
  [BookingStatus.Absent]: "error.main",
  [BookingStatus.Attended]: "success.main",
};
export function formatBookingStatusColor(status: BookingStatus) {
  return bookingStatusColorMapper[status];
}

const reservationStatusColorMapper = {
  [BookingStatus.Booked]: "info.main",
  [BookingStatus.Waiting]: "warning.main",
  [BookingStatus.Cancelled]: "text.disabled",
  [BookingStatus.Pending]: "warning.main",
  [BookingStatus.Absent]: "error.main",
  [BookingStatus.Attended]: "success.main",
};
export function formatReservationStatusColor(status: BookingStatus) {
  return reservationStatusColorMapper[status];
}

const dayMapper = {
  [Day.Monday]: "จันทร์",
  [Day.Tuesday]: "อังคาร",
  [Day.Wednesday]: "พุธ",
  [Day.Thursday]: "พฤหัสบดี",
  [Day.Friday]: "ศุกร์",
  [Day.Saturday]: "เสาร์",
  [Day.Sunday]: "อาทิตย์",
};
const dayStringMapper = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};
export function formatDayString(day: DayString) {
  return dayStringMapper[day] as Day;
}

const dayWeight = Object.keys(dayMapper);
export function formatDay(day: Day) {
  return dayMapper[day];
}
export function sortDay(d1: Day, d2: Day) {
  return dayWeight.indexOf(d1) - dayWeight.indexOf(d2);
}

function formatDuration(duration: Duration): string {
  return duration.reconfigure({ locale: "th-TH" }).toHuman();
}

export function formatDurationISO(iso: string): string {
  return formatDuration(fromDurationISO(iso));
}

export function formatPrice(price: number, unit = "บาท") {
  return price !== 0
    ? [formatNumber(price), unit].filter(Boolean).join(" ")
    : "-";
}

export function formatNumber(value: number): string {
  return value.toLocaleString();
}

const facilityTypeMapper = {
  [FacilityType.Pool]: "สระว่ายน้ำ",
  [FacilityType.Court]: "สนามกีฬา",
  [FacilityType.Equipment]: "อุปกรณ์",
  [FacilityType.Sauna]: "ห้องซาวน่า",
  [FacilityType.Cliff]: "หน้าผาจำลอง",
  [FacilityType.MeetingRoom]: "ห้องประชุม",
  [FacilityType.NapRoom]: "ห้องพักผ่อน",
  [FacilityType.Other]: "อื่นๆ",
};
export function formatFacilityType(type: FacilityType) {
  return facilityTypeMapper[type];
}

const facilityBookingStatusMapper = {
  [FacilityBookingStatus.Approved]: "จองสำเร็จ",
  [FacilityBookingStatus.Pending]: "รอการชำระค่าบริการ",
  [FacilityBookingStatus.Rejected]: "หลักฐานการชำระเงินไม่ถูกต้อง",
  [FacilityBookingStatus.Cancelled]: "ยกเลิก",
};
export function formatFacilityBookingStatus(status: FacilityBookingStatus) {
  return facilityBookingStatusMapper[status];
}

const facilityBookingStatusColorMapper = {
  [FacilityBookingStatus.Approved]: "success.main",
  [FacilityBookingStatus.Pending]: "warning.main",
  [FacilityBookingStatus.Rejected]: "error.main",
  [FacilityBookingStatus.Cancelled]: "text.secondary",
};
export function formatFacilityBookingStatusColor(
  status: FacilityBookingStatus
) {
  return facilityBookingStatusColorMapper[status];
}
