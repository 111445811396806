import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {
  CheckCircleOutline,
  ErrorOutline,
  ReportProblemOutlined,
} from "@mui/icons-material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { theme } from "./theme";
import { AuthenticationProvider } from "./features/authentication/contexts/AuthenticationContext";
import { AuthenticatedGuard } from "./features/authentication/guards/AuthenticatedGuard";
import { NonAuthenticatedGuard } from "./features/authentication/guards/NonAuthenticatedGuard";
import { SignInPage } from "./features/authentication/pages/SignInPage";
import { ClassListPage } from "./features/class/pages/ClassListPage/ClassListPage";
import { ClassDetailPage } from "./features/class/pages/ClassDetailPage/ClassDetailPage";
import { ScheduleListPage } from "./features/schedule/pages/ScheduleListPage/ScheduleListPage";
import { BookingListPage } from "./features/booking/pages/BookingListPage/BookingListPage";
import { UpcomingListPage } from "./features/booking/pages/UpcomingListPage/UpcomingListPage";
import { TrainerListPage } from "./features/trainer/pages/TrainerListPage/TrainerListPage";
import { TrainerDetailPage } from "./features/trainer/pages/TrainerDetailPage/TrainerDetailPage";
import { HistoryListPage } from "./features/booking/pages/HistoryListPage/HistoryListPage";
import { MemberPage } from "./features/member/pages/MemberPage";
import { FacilityListPage } from "./features/facility/pages/FacilityListPage/FacilityListPage";
import { FacilityDetailPage } from "./features/facility/pages/FacilityDetailPage/FacilityDetailPage";
import { FacilityBookingListPage } from "./features/booking/pages/FacilityBookingListPage/FacilityBookingListPage";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App = () => (
  <Sentry.ErrorBoundary>
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            iconVariant={{
              success: <CheckCircleOutline />,
              error: <ErrorOutline />,
              warning: <ReportProblemOutlined />,
              info: <ErrorOutline />,
            }}
            style={{ whiteSpace: "pre-wrap" }}
          >
            <QueryClientProvider client={queryClient}>
              <AuthenticationProvider>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <SentryRoutes>
                    <Route
                      path="/auth/sign-in"
                      element={
                        <NonAuthenticatedGuard>
                          <SignInPage />
                        </NonAuthenticatedGuard>
                      }
                    />
                    <Route
                      path=""
                      element={<AuthenticatedGuard children={<Outlet />} />}
                    >
                      <Route
                        path="/"
                        element={<Navigate to="/classes" replace />}
                      />
                      <Route
                        path="*"
                        element={<Navigate to="/classes" replace />}
                      />
                      <Route path="classes">
                        <Route index element={<ClassListPage />} />
                        <Route path=":id" element={<ClassDetailPage />} />
                      </Route>
                      <Route path="member" element={<MemberPage />} />
                      <Route path="schedules" element={<ScheduleListPage />} />
                      <Route path="trainers">
                        <Route index element={<TrainerListPage />} />
                        <Route path=":id" element={<TrainerDetailPage />} />
                      </Route>
                      <Route path="my-bookings" element={<BookingListPage />}>
                        <Route index element={<UpcomingListPage />} />
                        <Route path="history" element={<HistoryListPage />} />
                        <Route
                          path="facility"
                          element={<FacilityBookingListPage />}
                        />
                      </Route>
                      <Route path="facilities">
                        <Route index element={<FacilityListPage />} />
                        <Route path=":id" element={<FacilityDetailPage />} />
                      </Route>
                    </Route>
                  </SentryRoutes>
                </LocalizationProvider>
              </AuthenticationProvider>
            </QueryClientProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  </Sentry.ErrorBoundary>
);
