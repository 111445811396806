import { Dialog } from "@mui/material";

import { TrainerDetailCard } from "@/components/TrainerDetailCard";
import { DialogCloseButton } from "@/features/schedule/components/ClassDetailDialog";

import type { Trainer } from "@/models";

export function TrainerDetailDialog({
  open: isOpen,
  onClose,
  trainer,
}: {
  open: boolean;
  onClose: () => void;
  trainer: Trainer;
}) {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth={false}
      scroll="body"
      PaperProps={{
        sx: {
          borderRadius: "24px",
        },
      }}
    >
      {onClose ? <DialogCloseButton onClose={onClose} /> : null}
      <TrainerDetailCard data={trainer} isFetching={false} />
    </Dialog>
  );
}
