import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  Card,
  Divider,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import QRCode from "react-qr-code";

import { getMemberProfile, logout } from "@/services/member";
import { PurchaseType } from "@/models";
import {
  signOut,
  useAuthentication,
  useCurrentUser,
} from "@/features/authentication/contexts/AuthenticationContext";
import { Title } from "@/components/Title";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { theme } from "@/theme";
import { configs } from "@/configs";

import { ProductContainer } from "../components/ProductContainer";
import { MemberCard } from "../components/MemberCard";

import type { AxiosErrorWithData } from "@/client/api";
import type { Member } from "@/models";

const QUERY_KEY = "getMemberProfile";

export function MemberPage() {
  const [, dispatch] = useAuthentication();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [tab, setTab] = useState("member-card");

  const {
    fitnessCenter: { hasPermissionGroupControl },
  } = useCurrentUser();

  const { data: memberProfileData, isFetching } = useQuery([QUERY_KEY], () =>
    getMemberProfile()
  );

  const { mutate, isLoading: isLoadingLogout } = useMutation(logout, {
    onSuccess: async () => {
      await signOut(dispatch);
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
    },
  });

  if (isFetching || !memberProfileData) return <></>;

  const onClickSignOut = () => {
    setOpenConfirmDialog(true);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) =>
    setTab(newValue);

  const confirmDialog = {
    maxWidth: "xs" as const,
    open: openConfirmDialog,
    onClose: () => setOpenConfirmDialog(false),
    onConfirm: () => {
      mutate();
    },
    title: "คุณต้องการออกจากระบบหรือไม่",
    confirmMessage: "ออกจากระบบ",
    loading: isLoadingLogout,
  };

  const isShowTab = hasPermissionGroupControl;
  const isShowMemberCard = !isShowTab || tab === "member-card";
  const isShowDoorCard = tab === "door";

  const tabSx = { fontSize: { xs: 14, md: 16 }, width: { xs: 155, md: 200 } };

  return (
    <Stack alignItems="center" my={{ xs: 4, md: 7 }} gap={{ xs: 4, md: 7 }}>
      <Title title="สมาชิก" />
      {isShowTab && (
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          sx={{
            ".MuiTabs-flexContainer": {
              gap: 4,
            },
          }}
        >
          <Tab value="member-card" label="ข้อมูลสมาชิก" sx={tabSx} />
          <Tab value="door" label="สแกนเข้าฟิตเนส" sx={tabSx} />
        </Tabs>
      )}
      {isShowMemberCard && (
        <MemberCardSection memberProfileData={memberProfileData} />
      )}
      {isShowDoorCard && <DoorQRCodeSection memberId={memberProfileData.id} />}
      <Button color="error" variant="contained" onClick={onClickSignOut}>
        ออกจากระบบ
      </Button>
      <ConfirmDialog {...confirmDialog} />
    </Stack>
  );
}

type MemberCardSectionProps = {
  memberProfileData: Member;
};

function MemberCardSection({ memberProfileData }: MemberCardSectionProps) {
  const trainingQuotas = memberProfileData.personalTrainingQuota.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (quota) =>
          quota.startedAt.toMillis() === value.startedAt.toMillis() &&
          quota.endedAt.toMillis() === value.endedAt.toMillis() &&
          quota.product.name === value.product.name
      )
  );

  return (
    <Card elevation={3} sx={{ p: { xs: 2, md: 8 } }}>
      <MemberCard memberProfileData={memberProfileData} />
      <Typography
        variant="h2"
        mb={{ xs: 1, md: 2 }}
        sx={{
          typography: { xs: "subtitle1", md: "h5" },
        }}
      >
        ข้อมูลสินค้า
      </Typography>
      <Divider />
      <Box
        display="grid"
        mt={3}
        gridTemplateRows={{ xs: "1fr 1fr", md: "none" }}
        gridTemplateColumns={{ xs: "none", md: "1fr 1fr" }}
        gap={1}
      >
        <ProductContainer
          type={PurchaseType.Membership}
          data={memberProfileData.memberships}
        />
        <ProductContainer type={PurchaseType.ProductPT} data={trainingQuotas} />
      </Box>
    </Card>
  );
}

type DoorQRCodeSectionProps = {
  memberId: number;
};

function DoorQRCodeSection({ memberId }: DoorQRCodeSectionProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const code = `${configs.memberDoorCodePrefix}${memberId}`;

  return (
    <Paper
      sx={{ width: { xs: 342, md: 440 }, borderRadius: 2, p: { xs: 2, md: 4 } }}
    >
      <Stack gap={2} justifyContent="center" alignItems="center">
        <Typography sx={{ typography: { xs: "subtitle1", md: "h5" } }}>
          คิวอาร์โคดสำหรับใช้ในการเข้าฟิตเนส
        </Typography>
        <Box p={4}>
          <QRCode value={code} size={isMobile ? 220 : 300} />
        </Box>
      </Stack>
    </Paper>
  );
}
