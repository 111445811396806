import { Card, Divider, Stack } from "@mui/material";
import { Fragment } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

import { getHistoryBookings } from "@/services/booking";
import { EmptyResultIcon } from "@/components/EmptyResultIcon";
import { Pagination } from "@/components/Pagination";
import { CircularLoading } from "@/components/CircularLoading";

import { BookingCard } from "../../components/BookingCard";

export function HistoryListPage() {
  const [searchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page") ?? "1") || 1;
  const { data: raw, isFetching } = useQuery(["my-bookings", page], () =>
    getHistoryBookings(`page=${page}`)
  );
  const schedules = raw?.data ?? [];
  return (
    <Stack gap={5} alignItems="center">
      <Card
        sx={{
          mt: 6,
          maxWidth: "980px",
          width: "100%",
          textAlign: "-webkit-center",
        }}
      >
        {!isFetching ? (
          <>
            {schedules.length === 0 && (
              <Stack width="100vw" maxWidth={980} mx={{ xs: -4, md: -6 }}>
                <EmptyResultIcon variant="list" />
              </Stack>
            )}
            {schedules.map((item) => (
              <Fragment key={item.id}>
                <BookingCard data={item} isHistory />
                <Divider sx={{ width: "90%" }} />
              </Fragment>
            ))}
          </>
        ) : (
          <CircularLoading
            width="100vw"
            maxWidth={980}
            height={200}
            mx={{ xs: -4, md: -6 }}
          />
        )}
      </Card>
      <Pagination total={raw?.total} />
    </Stack>
  );
}
