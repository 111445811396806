import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useController, useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useSearchParams } from "react-router-dom";

import { FormDatePicker } from "@/components/DatePicker";
import { FormTimeSelect } from "@/components/TimeSelect";
import { FormSelect, Select } from "@/components/Select";
import { datetime } from "@/lib/yup";
import { now } from "@/lib/dateTime";
import { formatTime } from "@/formatter";
import { getNearestHalfTime, setTime } from "@/utils";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import { FacilityBookingDialog } from "./FacilityBookingDialog";

import type { Control } from "react-hook-form";
import type { Facility } from "@/models";
import type { InferType } from "yup";

type FacilityBookingCardProps = {
  control: Control<FacilityBookingCardState>;
  data?: Facility;
};

export function FacilityBookingCard({
  control,
  data,
}: FacilityBookingCardProps) {
  const [dialog, setDialog] = useState("");
  const date = useWatch({ name: "date", control }) ?? now();
  const startTime = useWatch({ name: "startTime", control }) ?? "00:00";
  const hour = useWatch({ name: "hour", control });

  const {
    homeBranchId,
    fitnessCenter: { branches },
  } = useCurrentUser();
  const [searchParams] = useSearchParams();

  const branchId = searchParams.get("branchId") ?? homeBranchId.toString();

  const {
    field: { onChange },
  } = useController({ name: "endTime", control });
  const {
    field: { onChange: onChangeStart },
  } = useController({ name: "startTime", control });

  const start = setTime(date, startTime);
  const end = start.plus({ hour });

  const openedAt = data?.openedAt ?? now();
  const closedAt = data?.closedAt ?? now();

  const isToday = now().toISODate() === date.toISODate();
  const isShiftTime =
    isToday && now().toFormat("HH:mm") > openedAt.toFormat("HH:mm");

  const optionsTimeRange = {
    start: isShiftTime ? getNearestHalfTime(now()) : openedAt,
    end: closedAt.minus({ hour }),
  };

  useEffect(() => {
    if (data) {
      onChangeStart(
        formatTime(isShiftTime ? getNearestHalfTime(now()) : openedAt)
      );
    }
  }, [onChangeStart, data, isShiftTime, openedAt]);

  useEffect(() => {
    onChange(formatTime(end));
  }, [onChange, end]);

  if (!data) return <></>;

  const openDialog = () => setDialog("facility-booking");
  const onCloseDialog = () => setDialog("");

  const bookingDialog = {
    open: dialog === "facility-booking",
    data,
    start,
    end,
    onClose: onCloseDialog,
    selectedBranch: {
      id: +branchId,
      name: branches.find(({ id }) => id === +branchId)?.name ?? "-",
    },
  };

  return (
    <Card sx={{ mt: { xs: -4, md: -5 } }}>
      <CardContent
        sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: "40px!important" } }}
      >
        <Grid
          container
          columnSpacing={{ xs: 1, md: 3 }}
          rowSpacing={{ xs: 3, md: 2 }}
          columns={16}
        >
          <Grid item xs={16}>
            <Select
              label="สาขา"
              name="branch"
              value={+branchId}
              FormControlProps={{ fullWidth: true }}
              disabled
            >
              {branches.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={16} md={3} order={1}>
            <FormDatePicker
              name="date"
              control={control}
              label="วันที่"
              disablePast
              TextFieldProps={{ fullWidth: true }}
            />
          </Grid>
          <Grid item xs={16} md={7} order={2}>
            <Stack direction="row" gap={{ xs: 1, md: 3 }} width="100%">
              <FormTimeSelect
                label="เวลาเริ่ม"
                name="startTime"
                control={control}
                FormControlProps={{ fullWidth: true }}
                optionsProps={optionsTimeRange}
              />
              <Typography variant="h6" mt={1.75}>
                -
              </Typography>
              <FormTimeSelect
                label="เวลาสิ้นสุด"
                name="endTime"
                control={control}
                disabled
                FormControlProps={{ fullWidth: true }}
              />
            </Stack>
          </Grid>
          <Grid item xs={16} md={3} order={3}>
            <FormSelect
              label="ระยะเวลา"
              name="hour"
              control={control}
              FormControlProps={{ fullWidth: true }}
            >
              <MenuItem value={1}>1 ชั่วโมง</MenuItem>
              <MenuItem value={2}>2 ชั่วโมง</MenuItem>
              <MenuItem value={3}>3 ชั่วโมง</MenuItem>
            </FormSelect>
          </Grid>
          <Grid
            item
            xs={16}
            md={3}
            order={5}
            textAlign="center"
            alignSelf="center"
          >
            <Button
              variant="contained"
              fullWidth
              onClick={openDialog}
              sx={{ width: { xs: "50%", md: "100%" } }}
            >
              จอง
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <FacilityBookingDialog {...bookingDialog} />
    </Card>
  );
}

const schema = yup.object({
  date: datetime(),
  startTime: yup.string(),
  endTime: yup.string(),
  hour: yup.number(),
});

type FacilityBookingCardState = InferType<typeof schema>;

const resolver = yupResolver(schema);

const defaultValues = {
  date: now().startOf("day"),
  startTime: formatTime(getNearestHalfTime(now())),
  endTime: formatTime(getNearestHalfTime(now().plus({ hour: 1 }))),
  hour: 1,
} as FacilityBookingCardState;

export function useFacilityBookingCardForm() {
  return useForm({ resolver, defaultValues });
}
