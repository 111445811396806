import { Pagination as PaginationMui } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { configs } from "@/configs";
import { mapOptional } from "@/utils";

import type { PaginationProps as PaginationMuiProps } from "@mui/material";

type PaginationProps = {
  total: number | undefined;
};
export function Pagination({ total }: PaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page") ?? "1") || 1;

  const onChangePage: PaginationMuiProps["onChange"] = (_, page: number) => {
    if (page === 1) {
      searchParams.delete("page");
    } else {
      searchParams.set("page", page.toString());
    }

    setSearchParams(searchParams);
  };

  const pageCount =
    mapOptional(total, (t) => Math.ceil(t / configs.limitPerPage)) ?? 1;

  return (
    <PaginationMui page={page} count={pageCount || 1} onChange={onChangePage} />
  );
}
