import { formatDate } from "@/formatter";
import { Member } from "@/models";
import { theme } from "@/theme";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import QRCode from "react-qr-code";

type QRCodeDialogProps = {
  onClose: () => void;
  isOpen: boolean;
  memberProfileData: Member;
};
export const QRCodeDialog = ({
  onClose,
  isOpen,
  memberProfileData,
}: QRCodeDialogProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "white",
          borderRadius: "24px",
        },
      }}
    >
      {onClose ? (
        <IconButton
          size="large"
          aria-label="close"
          disableRipple
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 24,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
      <Stack justifyContent="center" alignItems="center" pt={12} pb={6} gap={4}>
        <Typography sx={{ typography: { xs: "subtitle1", md: "h5" } }}>
          คิวอาร์โคดสำหรับใช้ในการเช็คอินฟิตเนส
        </Typography>
        <Box maxHeight={{ sm: 300 }} maxWidth={{ sm: 300 }}>
          <QRCode
            value={memberProfileData.code ?? ""}
            size={isMobile ? 220 : 300}
          />
        </Box>
        <Typography variant="h6" color="black">
          EXP :{" "}
          {memberProfileData.membership
            ? formatDate(memberProfileData.membership.endedAt)
            : ""}
        </Typography>
      </Stack>
    </Dialog>
  );
};
