import { Grid, Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Title } from "@/components/Title";
import { AllBranchSelect } from "@/components/AllBranchSelect";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { getFacilities } from "@/services/facility";

import { FacilityCard } from "../../components/FacilityCard";

import type { SelectChangeEvent } from "@mui/material";

const QUERY_KEY = "classes";

export function FacilityListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    homeBranchId,
    fitnessCenter: { branches },
  } = useCurrentUser();

  const branchId = searchParams.get("branchId") ?? homeBranchId.toString();
  const { data: raw } = useQuery([QUERY_KEY, branchId], () => getFacilities());

  const title = "สิ่งอำนวยความสะดวก";

  const onChangeBranch = (event: SelectChangeEvent<number>) => {
    searchParams.set("branchId", event.target.value.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const data = raw ?? [];

  const isShowBranch = branches.length > 1;

  useEffect(() => {
    if (!searchParams.get("branchId")) {
      searchParams.set("branchId", homeBranchId.toString());
      setSearchParams(searchParams, { replace: true });
    }
  }, [branchId, homeBranchId, searchParams, setSearchParams]);

  return (
    <Stack
      mt={{ xs: 4, md: 7 }}
      gap={{ xs: 4, md: 7 }}
      px={{ xs: 3, md: 14 }}
      alignItems="center"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Title title={title} />
      {isShowBranch && (
        <AllBranchSelect
          value={+branchId}
          onChange={onChangeBranch}
          FormControlProps={{ sx: { width: "100%", maxWidth: 428 } }}
        />
      )}
      <Grid container spacing={{ xs: 2, md: 8 }}>
        {data.map((item) => (
          <Grid item md={3} xs={6} key={item.id} display="grid">
            <FacilityCard data={item} branchId={+branchId} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
