import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { formatNumber, formatTimeRange } from "@/formatter";
import { CircularLoading } from "@/components/CircularLoading";
import { theme } from "@/theme";
import { mapOptional } from "@/utils";

import type { Facility } from "@/models";
import { configs } from "@/configs";

type FacilityDetailCardProps = {
  data?: Facility;
  isFetching: boolean;
};

export function FacilityDetailCard({
  data,
  isFetching,
}: FacilityDetailCardProps) {
  if (!data) return <></>;

  const {
    thumbnailUrl,
    name,
    description,
    openedAt,
    closedAt,
    guestFee,
    memberFee,
  } = data;

  const hasFee = !!guestFee || !!memberFee;

  return (
    <Card>
      {!isFetching ? (
        <CardContent sx={{ p: 0, pb: "0!important" }}>
          <Grid container spacing={{ xs: 2, md: 7 }}>
            <Grid item xs={12} md={4} height={{ xs: 336, md: 392 }}>
              <Box
                component="img"
                src={thumbnailUrl ?? configs.defaultImageURL}
                height="100%"
                width="100%"
                sx={{ objectFit: "cover", objectPosition: "center" }}
              />
            </Grid>
            <Grid item xs={12} md={8} pr={{ xs: 0, md: 7 }}>
              <Stack mt={{ xs: 0, md: 5 }} px={{ xs: 2, md: 0 }}>
                <Stack gap={1}>
                  <Typography
                    sx={{ typography: { xs: "subtitle1", md: "h5" } }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    color={`${theme.palette.text.secondary}!important`}
                    sx={{
                      typography: { xs: "subtitle2", md: "subtitle1" },
                    }}
                  >
                    รายละเอียดเพิ่มเติม
                  </Typography>
                  <Typography color="text.secondary" variant="body2">
                    {description ?? "-"}
                  </Typography>
                </Stack>
                <Divider sx={{ my: { xs: 2, md: 3 } }} />
                <Grid container mb={{ xs: 2, md: 4 }} pb={1}>
                  <Grid item xs={12} md={6}>
                    <Stack gap={{ xs: 0.5, md: 1 }}>
                      <Typography
                        color={`${theme.palette.text.secondary}!important`}
                        sx={{
                          typography: { xs: "subtitle2", md: "subtitle1" },
                        }}
                        mb={{ xs: 0.5, md: 1 }}
                      >
                        เวลาให้บริการ
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {formatTimeRange(openedAt, closedAt)}
                      </Typography>
                    </Stack>
                  </Grid>
                  {hasFee && (
                    <Grid item xs={12} md={6} mt={{ xs: 3, md: 0 }}>
                      <Stack gap={{ xs: 1, md: 2 }}>
                        <Typography
                          color={`${theme.palette.text.secondary}!important`}
                          sx={{
                            typography: { xs: "subtitle2", md: "subtitle1" },
                          }}
                        >
                          ค่าบริการเพิ่มเติม
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {mapOptional(memberFee, formatNumber) ?? "-"} บาท
                          สำหรับสมาชิก/ครั้ง
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {mapOptional(guestFee, formatNumber) ?? "-"} บาท
                          สำหรับบุคคลภายนอก/ครั้ง
                        </Typography>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <CircularLoading height={336} />
      )}
    </Card>
  );
}
