import { Helmet } from "react-helmet-async";
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CalendarPicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

import { now } from "@/lib/dateTime";
import { getSchedules } from "@/services/class";
import { BookingDialog } from "@/components/BookingDialog";
import { EmptyResultIcon } from "@/components/EmptyResultIcon";
import { Title } from "@/components/Title";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { AllBranchSelect } from "@/components/AllBranchSelect";
import { DatePicker } from "@/components/DatePicker";
import { CircularLoading } from "@/components/CircularLoading";

import { ScheduleCard } from "../../components/ScheduleCard";
import { ClassDetailDialog } from "../../components/ClassDetailDialog";

import type { SelectChangeEvent } from "@mui/material";
import type { DateTime } from "luxon";

const QUERY_KEY = "schedules";

export function ScheduleListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    homeBranchId,
    fitnessCenter: { branches },
  } = useCurrentUser();
  const theme = useTheme();
  const [date, setDate] = useState(now());
  const title = "ตารางเวลา";
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [dialogProps, setDialogProps] = useState({ dialog: "", id: "" });

  const branchId = searchParams.get("branchId") ?? homeBranchId.toString();

  const { data: raw, isFetching } = useQuery(
    [QUERY_KEY, date.toISODate(), branchId],
    () => getSchedules({ start: date, end: date.endOf("day") })
  );

  useEffect(() => {
    if (!searchParams.get("branchId")) {
      searchParams.set("branchId", homeBranchId.toString());
      setSearchParams(searchParams, { replace: true });
    }
  }, [branchId, homeBranchId, searchParams, setSearchParams]);

  const { id, dialog } = dialogProps;

  const data = raw?.find((item) => item.id === +id);

  const onCloseDialog = () => setDialogProps({ dialog: "", id: "" });

  const onChangeDate = (newDate: DateTime | null) => {
    const date = newDate ?? now();
    const isTodayDate = date.toISODate() === now().toISODate();
    setDate(isTodayDate ? now() : date.startOf("day"));
  };

  const bookingDialog = {
    open: dialog === "class-booking",
    data,
    onClose: onCloseDialog,
  };

  const classDetailDialog = {
    open: dialog === "class-detail",
    id: data?.class?.id.toString(),
    onClose: onCloseDialog,
  };

  const isShowBranch = branches.length > 1;

  const onChangeBranch = (event: SelectChangeEvent<number>) => {
    searchParams.set("branchId", event.target.value.toString());
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <Stack px={4}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Title title={title} my={{ xs: 4, md: 7 }} />
      <Card>
        <CardContent sx={{ p: { xs: 2, md: 5 } }}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            gap={{ xs: 4, md: 5 }}
            mt={{ xs: 0, md: 4 }}
          >
            <Stack gap={4} width={{ xs: "100%", md: 428 }}>
              {isShowBranch && (
                <AllBranchSelect
                  value={+branchId}
                  onChange={onChangeBranch}
                  FormControlProps={{ sx: { order: { xs: 2, md: 1 } } }}
                />
              )}
              <Typography variant="h6" order={{ xs: 1, md: 2 }}>
                เลือกวันที่ต้องการจอง
              </Typography>
              <Box order={3}>
                {isMobile ? (
                  <DatePicker
                    disablePast
                    views={["day"]}
                    TextFieldProps={{ fullWidth: true }}
                    PopperProps={{ placement: "top-start" }}
                    value={date}
                    onChange={onChangeDate}
                  />
                ) : (
                  <CalendarPicker
                    disablePast
                    view="day"
                    date={date}
                    onChange={onChangeDate}
                  />
                )}
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "100%",
                maxHeight: {
                  xs: "calc(100vh - 464px)",
                  md: "calc(100vh - 318px)",
                },
                overflow: "scroll",
              }}
            >
              {!isFetching ? (
                <>
                  {raw?.length === 0 && <EmptyResultIcon variant="schedule" />}
                  {raw?.map((item, index) => (
                    <ScheduleCard
                      key={item.id}
                      data={item}
                      isLast={index + 1 === raw?.length}
                      setDialogProps={setDialogProps}
                    />
                  ))}
                </>
              ) : (
                <CircularLoading height={400} />
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <BookingDialog {...bookingDialog} />
      <ClassDetailDialog {...classDetailDialog} />
    </Stack>
  );
}
