import { useSnackbar } from "notistack";
import { useMutation } from "@tanstack/react-query";
import { Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ErrorIcon from "@mui/icons-material/Error";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { createTrainerBooking } from "@/services/trainer";
import { formatDate, formatProfile, formatTimeRange } from "@/formatter";
import { getAxiosErrorMessage } from "@/utils";

import type { DateTime } from "luxon";
import type { Trainer } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

type TrainerBookingDialogProps = {
  open: boolean;
  data: Trainer;
  start: DateTime;
  end: DateTime;
  onClose: () => void;
  selectedBranch: { id: number; name: string };
};

export function TrainerBookingDialog({
  open: isOpen,
  data,
  start,
  end,
  onClose,
  selectedBranch,
}: TrainerBookingDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id, profile } = data;

  const { mutate: add, isLoading } = useMutation(createTrainerBooking, {
    onSuccess: () => {
      enqueueSnackbar("การจองของคุณสำเร็จ", { variant: "success" });
      onClose();
      navigate(-1);
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getAxiosErrorMessage(error), { variant: "error" });
    },
  });

  const onConfirm = () => add({ start, end, trainerId: id });

  const durationInMinute = end.diff(start, ["minutes"]).minutes;

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title="ยืนยันการจอง"
      confirmMessage="ยืนยัน"
      loading={isLoading}
    >
      <Stack>
        <Typography variant="body1">รายละเอียดการจอง</Typography>
        <Grid container alignItems="center">
          <DateRangeIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="body1" mb={3} mt={3}>
            {formatDate(start)} {formatTimeRange(start, end)} (
            {durationInMinute}
            min)
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant="body2" color="text.disabled">
              ชื่อเทรนเนอร์
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{formatProfile(profile)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="text.disabled">
              สาขา
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{selectedBranch.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" gap={2}>
              <ErrorIcon color="error" />
              <Typography variant="body2" color="error.main">
                เมื่อทำการจองเทรนเนอร์แล้ว หากต้องการยกเลิกการจอง
                จะต้องยกเลิกการจองล่วงหน้าอย่างน้อย 24 ชั่วโมง
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </ConfirmDialog>
  );
}
