import { Divider } from "@mui/material";

import { theme } from "@/theme";

import type { SxProps } from "@mui/material";

export function DividerTitle({ sx }: { sx?: SxProps }) {
  return (
    <Divider
      sx={{
        width: { md: 60, xs: 36 },
        bgcolor: theme.palette.primary.main,
        borderColor: "transparent",
        borderWidth: 2,
        ...sx,
      }}
    />
  );
}
