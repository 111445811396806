import { Member } from "@/models";
import { api } from "@/client/api";
import { configs } from "@/configs";
import { parseDateTimeFromPrisma } from "@/utils";

export async function getMemberProfile(): Promise<Member> {
  const response = await api.get(`${configs.apiUrl}/member/profile`);
  return parseDateTimeFromPrisma(response.data) as Member;
}

export async function logout(): Promise<string> {
  const response = await api.post(`${configs.apiUrl}/auth/member-logout`);
  return response.data as string;
}
