import type { FirebaseOptions } from "@firebase/app";

const env = import.meta.env.VITE_ENV ?? "development";
const apiUrl = import.meta.env.VITE_REST_URL;
const liffId = import.meta.env.VITE_LINE_LIFF_ID;

const firebaseConfigs: Record<string, FirebaseOptions> = {
  development: {
    apiKey: "AIzaSyCvN5T2RmC1zCX9lt1nSDPQ0rwuqrOV-a0",
    authDomain: "fitup-dev.firebaseapp.com",
    projectId: "fitup-dev",
    storageBucket: "fitup-dev.appspot.com",
    messagingSenderId: "393956998711",
    appId: "1:393956998711:web:005bc478be23211cff49b2",
    measurementId: "G-4JFQWJHHV2",
  },
  production: {
    apiKey: "AIzaSyARgZ29ZT6qA6Ek5fswOf49zO9pKTrGM44",
    authDomain: "fitup-pro.firebaseapp.com",
    projectId: "fitup-pro",
    storageBucket: "fitup-pro.appspot.com",
    messagingSenderId: "557369696983",
    appId: "1:557369696983:web:1d6ae9df73d86623d8c692",
    measurementId: "G-22JV76GEF4",
  },
};

const liffConfigs: Record<string, { url: string }> = {
  development: {
    url: "https://dev-member.fitup.health",
  },
  production: {
    url: "https://member.fitup.health",
  },
};

const firebase = firebaseConfigs[env];
const liffUrl = liffConfigs[env].url;
const buildNumber = import.meta.env.VITE_BUILD_NUMBER ?? "";
const appVersion = import.meta.env.VITE_VERSION ?? "";
const version = appVersion ? `${appVersion.split(/^dev-|^v/)[1]}` : "localhost";
const timezone = "Asia/Bangkok";
const unknownErrorMessage = "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง";
const defaultImageURL = "/src/assets/default-image.svg";
const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
const limitPerPage = 10;
const memberDoorCodePrefix = 644;

export const configs = {
  env,
  apiUrl,
  liffId,
  liffUrl,
  firebase,
  version,
  buildNumber,
  timezone,
  unknownErrorMessage,
  defaultImageURL,
  sentryDSN,
  limitPerPage,
  memberDoorCodePrefix,
};
