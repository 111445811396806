import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";

export async function upload(path: string, file: File) {
  const { ref: fileRef } = await uploadBytes(
    ref(getStorage(), `${path}/${encodeURIComponent(file.name)}`),
    file
  );
  return await getDownloadURL(fileRef);
}
