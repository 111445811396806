import { Card, Divider, Stack } from "@mui/material";
import { Fragment, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

import { getUpcomingBookings } from "@/services/booking";
import { EmptyResultIcon } from "@/components/EmptyResultIcon";
import { ClassDetailDialog } from "@/features/schedule/components/ClassDetailDialog";
import { Pagination } from "@/components/Pagination";
import { transformTrainerProfile } from "@/utils";
import { CircularLoading } from "@/components/CircularLoading";

import { BookingCard } from "../../components/BookingCard";
import { CancelBookingDialog } from "./CancelBookingDialog";
import { TrainerDetailDialog } from "./TrainerDetailDialog";

import type { Trainer } from "@/models";

export function UpcomingListPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [dialogProps, setDialogProps] = useState({ dialog: "", id: "" });

  const page = parseInt(searchParams.get("page") ?? "1") || 1;
  const { data: raw, isFetching } = useQuery(["my-bookings", page], () =>
    getUpcomingBookings(`page=${page}`)
  );

  const { dialog, id } = dialogProps;

  const schedules = raw?.data ?? [];

  const data = schedules.find((booking) => booking.id === +id);

  const onClose = () => {
    setDialogProps({ dialog: "", id: "" });
    searchParams.delete("branchId");
    setSearchParams(searchParams, { replace: true });
  };

  const isClassDialog = dialog === "class-detail";
  const isTrainerDialog = dialog === "trainer-detail";

  const classDetailDialog = {
    open: isClassDialog,
    id,
    onClose,
  };

  const cancelBookingDialog = {
    open: dialog === "cancel-booking",
    data,
    onClose,
  };

  const trainerDetailDialog = {
    open: isTrainerDialog,
    trainer: {
      ...data?.schedule.scheduleTrainers?.[0].staff,
      trainerProfileData: transformTrainerProfile(
        data?.schedule.scheduleTrainers?.[0].staff.trainerProfile
      ),
    } as Trainer,
    onClose,
  };

  return (
    <Stack gap={5} alignItems="center">
      <Card
        sx={{
          mt: 6,
          maxWidth: "980px",
          width: "100%",
          textAlign: "-webkit-center",
        }}
      >
        {!isFetching ? (
          <>
            {schedules.length === 0 && (
              <Stack width="100vw" maxWidth={980} mx={{ xs: -4, md: -6 }}>
                <EmptyResultIcon variant="list" />
              </Stack>
            )}
            {schedules.map((item) => (
              <Fragment key={item.id}>
                <BookingCard data={item} setDialogProps={setDialogProps} />
                <Divider sx={{ width: "90%" }} />
              </Fragment>
            ))}
            {isClassDialog && <ClassDetailDialog {...classDetailDialog} />}
            <CancelBookingDialog {...cancelBookingDialog} />
            {isTrainerDialog && (
              <TrainerDetailDialog {...trainerDetailDialog} />
            )}
          </>
        ) : (
          <CircularLoading
            width="100vw"
            maxWidth={980}
            height={200}
            mx={{ xs: -4, md: -6 }}
          />
        )}
      </Card>
      <Pagination total={raw?.total} />
    </Stack>
  );
}
