import { useState, useEffect } from "react";

const detectScrollHeight = 600;

export function usePageBottom() {
  const [reachedBottom, setReachedBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offsetHeight = document.documentElement.offsetHeight;
      const innerHeight = window.innerHeight;
      const scrollTop = document.documentElement.scrollTop;

      const maxScrollHeight = offsetHeight - innerHeight;
      const isDetectScrollHeight = maxScrollHeight >= detectScrollHeight;

      const hasReachedBottom =
        offsetHeight - (innerHeight + scrollTop) <= maxScrollHeight * 0.5;

      setReachedBottom(isDetectScrollHeight ? hasReachedBottom : false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return reachedBottom;
}
