import { Navigate } from "react-router-dom";
import { useAuthentication } from "../contexts/AuthenticationContext";

export function NonAuthenticatedGuard({ children }: { children: JSX.Element }) {
  const [{ isAuthenticated }] = useAuthentication();

  if (isAuthenticated) {
    return <Navigate to="/classes" />;
  }

  return children;
}
