import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { Fragment } from "react";

import { configs } from "@/configs";
import { formatProfile, formatTimeRange } from "@/formatter";
import { BookingButton } from "@/components/BookingButton";

import type { MouseEventHandler } from "react";
import type { Schedule } from "@/models";

type ScheduleCardProps = {
  data: Schedule;
  isLast?: boolean;
  setDialogProps: ({ dialog, id }: { dialog: string; id: string }) => void;
};

export function ScheduleCard({
  data,
  setDialogProps,
  isLast = false,
}: ScheduleCardProps) {
  const {
    id,
    startedAt,
    endedAt,
    class: classSchedule,
    staffs,
    location,
    capacity,
    bookings,
  } = data;
  const time = endedAt.diff(startedAt, ["minutes"]).minutes;

  const thumbnailUrls = classSchedule?.thumbnailUrls ?? [];
  const name = classSchedule?.name ?? "";

  const onClickBooking: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setDialogProps({ dialog: "class-booking", id: id.toString() });
  };

  const onClickDetail: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setDialogProps({ dialog: "class-detail", id: id.toString() });
  };

  const bookingButton = {
    data,
    onClick: onClickBooking,
  };

  const activeBookings = bookings.filter(({ cancelledAt }) => !cancelledAt);
  const waitingBookings = bookings.filter(({ isWaiting }) => isWaiting);

  const waitingData = waitingBookings.length
    ? [{ label: "รอคิว", values: [`${waitingBookings.length} คน`] }]
    : [];

  const items = [
    {
      label: "ผู้สอน",
      values: staffs?.map(({ profile }) => formatProfile(profile)) || ["-"],
    },
    { label: "สถานที่", values: [location] },
    {
      label: "ผู้เข้าร่วม",
      values: [
        `${
          activeBookings.length >= capacity ? capacity : activeBookings.length
        }/${capacity} คน`,
      ],
    },
    ...waitingData,
  ];

  return (
    <>
      <Grid
        container
        columnSpacing={{ xs: 0, md: 4 }}
        rowSpacing={{ xs: 3, md: 4 }}
      >
        <Grid item xs={12} md={4} sx={{ px: { xs: 4, md: 0 } }}>
          <Box
            component="img"
            src={thumbnailUrls?.[0] ?? configs.defaultImageURL}
            sx={{
              width: "100%",
              height: 165,
              borderRadius: "12px",
              objectFit: "cover",
            }}
          />
        </Grid>
        <Grid item xs={12} md={5} sx={{ px: { xs: 2, md: 0 } }}>
          <Stack gap={1}>
            <Stack direction="row" gap={3} alignItems="center">
              <Typography variant="h6">
                {formatTimeRange(startedAt, endedAt)}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                ({time} min)
              </Typography>
            </Stack>
            <Typography noWrap variant="subtitle1">
              {name}
            </Typography>
            <Box display="grid" gridTemplateColumns="80px 1fr" rowGap={1}>
              {items.map(({ label, values }) => (
                <Fragment key={label}>
                  <Typography variant="subtitle2" color="text.disabled">
                    {label}
                  </Typography>
                  <Stack gap={1}>
                    {values.map((value, index) => (
                      <Typography key={`${value} ${index}`} variant="body2">
                        {value}
                      </Typography>
                    ))}
                  </Stack>
                </Fragment>
              ))}
              <Box gridColumn="1/-1">
                <Button
                  size="small"
                  sx={{ color: "text.primary" }}
                  onClick={onClickDetail}
                >
                  ดูรายละเอียดเพิ่มเติม {">"}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          display="flex"
          textAlign="center"
          alignItems="flex-start"
        >
          <BookingButton
            {...bookingButton}
            sx={{ width: { xs: "50%", md: "100%" }, maxWidth: 146 }}
          />
        </Grid>
      </Grid>
      {!isLast && <Divider sx={{ my: 3 }} />}
    </>
  );
}
