import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { getAxiosErrorMessage } from "@/utils";
import { ImageUploader } from "@/components/ImageUploader";
import { updateFacilityBookingSlip } from "@/services/booking";
import { FacilityBooking } from "@/models";

import type { AxiosErrorWithData } from "@/client/api";
import type { FileForm } from "@/components/ImageUploader";

export type UploadBokingDialogProps = {
  open: boolean;
  data?: FacilityBooking;
  onClose: () => void;
  fetchKeys: string[];
};

export function UploadBokingDialog({
  open: isOpen,
  data,
  onClose,
  fetchKeys = [],
}: UploadBokingDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [file, setFile] = useState<FileForm>({
    url: "",
    file: undefined,
  });

  const { mutate: update, isLoading } = useMutation(updateFacilityBookingSlip, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มหลักฐานการจองสำเร็จ", { variant: "success" });
      await queryClient.refetchQueries(fetchKeys);
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getAxiosErrorMessage(error), { variant: "error" });
    },
  });

  useEffect(() => {
    if (data) {
      setFile({ url: data.paymentSlipUrl });
    }
  }, [data]);

  if (!data) return <></>;

  const onConfirm = () => {
    if (!file.url) {
      return;
    }
    update({ bookingId: +data.id, imageUrl: file.url });
  };

  const close = () => {
    setFile({ url: "", file: undefined });
    onClose();
  };

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={close}
      onConfirm={onConfirm}
      title="เพิ่มหลักฐานการจอง"
      confirmMessage="ยืนยัน"
      loading={isLoading}
    >
      <ImageUploader
        value={file}
        onChange={setFile}
        module={"facility-booking"}
      />
    </ConfirmDialog>
  );
}
