import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { configs } from "@/configs";
import { formatTimeRange } from "@/formatter";

import type { MouseEventHandler } from "react";
import type { Facility } from "@/models";

export type FacilityCardProps = {
  data: Facility;
  branchId: number;
};

export function FacilityCard({ data, branchId }: FacilityCardProps) {
  const { id, name, thumbnailUrl, openedAt, closedAt } = data;
  const navigate = useNavigate();

  const onClick: MouseEventHandler<HTMLButtonElement> = () =>
    navigate(`${id.toString()}?branchId=${branchId}`);

  return (
    <Card>
      <CardMedia
        component="img"
        height={160}
        image={thumbnailUrl ?? configs.defaultImageURL}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100% - 160px)",
        }}
      >
        <Typography
          gutterBottom
          variant="h6"
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
          }}
        >
          {name}
        </Typography>
        <Typography variant="body2" color="text.disabled" mb={2}>
          เวลาให้บริการ : {formatTimeRange(openedAt, closedAt)} น.
        </Typography>
        <Button
          size="medium"
          variant="contained"
          fullWidth
          sx={{ mt: "auto" }}
          onClick={onClick}
        >
          รายละเอียด
        </Button>
      </CardContent>
    </Card>
  );
}
