import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import QRCode from "react-qr-code";

import BanIcon from "@/components/Icons/Ban";
import LockIcon from "@/components/Icons/Lock";
import { formatDate } from "@/formatter";
import { now } from "@/lib/dateTime";
import DefaultLogo from "@/assets/default-logo.webp";
import CardBackground from "@/assets/member-card-bg.svg";

import { QRCodeDialog } from "./QRCodeDialog";

import type { Member } from "@/models";

export function MemberCard({
  memberProfileData,
}: {
  memberProfileData: Member;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showQrCodeDialog, setShowQrCodeDialog] = useState(false);

  if (!memberProfileData.membership) return <div>no membership</div>;

  return (
    <Stack direction="row" justifyContent="center" mb={{ xs: 3, md: 5 }}>
      <Box
        display="flex"
        position="relative"
        width={{ xs: "310px", md: "480px" }}
        height={{ xs: "198px", md: "306px" }}
        borderRadius={2}
        overflow="hidden"
        boxShadow="0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)"
        p={{ xs: 2.5, md: 4 }}
        pr={{ xs: 1.5, md: 2 }}
        pb={{ xs: 1.25, md: 2 }}
        sx={{
          backgroundColor: memberProfileData.memberCards?.backgroundColor,
          backgroundImage: `url(${
            memberProfileData.memberCards?.backgroundUrl || CardBackground
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          ":hover": {
            cursor: "pointer",
          },
        }}
        onClick={() =>
          memberProfileData.banUntil ? null : setShowQrCodeDialog(true)
        }
      >
        <CardStatus memberProfileData={memberProfileData} />
        <Stack
          width={286}
          sx={{
            color: memberProfileData.memberCards?.textColor ?? "white",
          }}
        >
          <Box
            component="img"
            src={memberProfileData.memberCards?.logoUrl || DefaultLogo}
            alt="fitness logo"
            width={{ xs: 160, md: 240 }}
            height={{ xs: 40, md: 60 }}
            sx={{
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
          <Typography
            mt={{ xs: 1.5, md: 2 }}
            letterSpacing="2px"
            fontSize={{ xs: "0.875rem", md: "1.415rem" }}
          >
            MEMBERSHIP CARD
          </Typography>
          <Typography mt={1} fontSize={{ xs: "0.687rem", md: "1.06rem" }}>
            {`${memberProfileData.profile.firstName} 
            ${memberProfileData.profile.lastName}`}
          </Typography>
          <Typography mt="2px" fontSize={{ xs: "0.534rem", md: "0.825rem" }}>
            {memberProfileData.code}
          </Typography>
        </Stack>
        <Stack
          justifyContent="flex-end"
          alignItems="center"
          flexGrow={1}
          sx={{
            color: memberProfileData.memberCards?.textColor ?? "white",
          }}
        >
          <Box
            borderRadius="4px"
            p={{ xs: 0.5, md: 1 }}
            height={{ xs: 86, md: 132 }}
            width={{ xs: 86, md: 132 }}
            bgcolor="white"
          >
            <QRCode
              value={memberProfileData.code ?? ""}
              size={isMobile ? 78 : 116}
            />
          </Box>
          <Typography pt={0.5} fontSize={{ xs: "0.458rem", md: "0.708rem" }}>
            EXP : {formatDate(memberProfileData.membership.endedAt)}
          </Typography>
        </Stack>
      </Box>
      <QRCodeDialog
        isOpen={showQrCodeDialog}
        memberProfileData={memberProfileData}
        onClose={() => setShowQrCodeDialog(false)}
      />
    </Stack>
  );
}

function CardStatus({ memberProfileData }: { memberProfileData: Member }) {
  if (
    !memberProfileData.membership ||
    (!memberProfileData.banUntil &&
      !(now() > memberProfileData.membership.endedAt))
  )
    return null;

  const statusMessages = memberProfileData.banUntil
    ? "บัญชีของคุณถูกระงับ"
    : "สมาชิกหมดอายุ";

  return (
    <Stack
      position="absolute"
      rowGap={1.5}
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      top={0}
      left={0}
      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
    >
      {memberProfileData.banUntil ? (
        <BanIcon
          color={memberProfileData.memberCards?.textColor ?? "#3E3F80"}
        />
      ) : (
        <LockIcon
          color={memberProfileData.memberCards?.textColor ?? "#3E3F80"}
        />
      )}
      <Typography
        sx={{
          color: `${memberProfileData.memberCards?.textColor ?? "#3E3F80"}`,
          typography: { xs: "subtitle1", md: "h6" },
        }}
      >
        {statusMessages}
      </Typography>
    </Stack>
  );
}
