import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { fromISO, fromJSDate, now } from "./lib/dateTime";
import {
  Booking,
  BookingStatus,
  DayString,
  OfficeDay,
  TrainerProfile,
  WorkingDays,
} from "./models";
import { MutableRefObject, Ref, useEffect, useRef } from "react";
import { formatDayString } from "@/formatter";
import { configs } from "@/configs";
import { AxiosErrorWithData } from "@/client/api";

export function mapOptional<T, U>(
  value: T | null,
  transform: (value: NonNullable<T>) => U
): U | null;
export function mapOptional<T, U>(
  value: T | undefined,
  transform: (value: NonNullable<T>) => U
): U | undefined;
export function mapOptional<T, U>(
  value: T | null | undefined,
  transform: (value: NonNullable<T>) => U,
  defaultValue?: U
): U | null | undefined {
  if (typeof value === "undefined" || value === null) {
    return defaultValue;
  }

  return transform(value as NonNullable<T>);
}

export function useRequireParams<Key extends string | number | symbol = string>(
  keys: Key[]
): Record<Key, string> {
  const params = useParams();

  if (!keys.every((key) => key in params)) {
    throw new Error("Input key not in params");
  }

  return params as unknown as Record<Key, string>;
}

export function isTimeAfter(time: string, target: string) {
  return (
    parseInt(time.replace(":", ""), 10) > parseInt(target.replace(":", ""), 10)
  );
}

export function pickParams(
  searchParams: URLSearchParams,
  keys: string[] = []
): string {
  const params: string[] = [];
  const selectedKeys = [
    "query",
    "page",
    "limit",
    "status",
    "sort",
    "sortType",
  ].concat(keys);

  searchParams.forEach((value, key) => {
    if (selectedKeys.includes(key)) {
      params.push(`${key}=${value}`);
    }
  });
  return params.join("&");
}

// TODO: refactor
export function parseDateTimeFromPrisma(input: unknown): unknown {
  const isoDatePattern =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/;
  if (
    typeof input === "string" &&
    isNaN(+input) &&
    isoDatePattern.test(input)
    //  other way when iso pattern is not work
    // fromISO(input).isValid &&
    // !isNaN(new Date(input).getTime())
  ) {
    return fromISO(input);
  }
  if (input instanceof Date) {
    return fromJSDate(input);
  }

  if (Array.isArray(input) && input !== null) {
    return input.map((item: unknown) => parseDateTimeFromPrisma(item));
  }

  if (input instanceof Object) {
    const data: Record<string, unknown> = {};
    Object.keys(input).forEach((key) => {
      const newInput = input as Record<string, unknown>;
      data[key] = parseDateTimeFromPrisma(newInput[key]);
    });
    return data;
  }

  return input;
}

export function parseURLSearchParams(object: Record<string, string>): string {
  return new URLSearchParams(object).toString();
}

// TODO: refactor
export function parseISOToPrisma(input: unknown): unknown {
  if (input instanceof Date) {
    return fromJSDate(input).toISO();
  }

  if (input instanceof DateTime) {
    return input.toISO();
  }

  if (Array.isArray(input) && input !== null) {
    return input.map((item: unknown) => parseISOToPrisma(item));
  }

  if (input instanceof Object) {
    const data: Record<string, unknown> = {};
    Object.keys(input).forEach((key) => {
      const newInput = input as Record<string, unknown>;
      data[key] = parseISOToPrisma(newInput[key]);
    });
    return data;
  }

  return input;
}

export function getBookingStatus({
  cancelledAt,
  isWaiting,
  isPending,
  attendedAt,
  schedule: { startedAt },
}: Booking) {
  return cancelledAt
    ? BookingStatus.Cancelled
    : isWaiting
    ? BookingStatus.Waiting
    : isPending
    ? BookingStatus.Pending
    : attendedAt
    ? BookingStatus.Attended
    : startedAt < now()
    ? BookingStatus.Absent
    : BookingStatus.Booked;
}

export function mergeRefs<T>(...refs: Array<Ref<T> | undefined>) {
  return (node: T) => {
    refs.forEach((ref) => {
      if (!ref) return;
      if (typeof ref === "object") {
        (ref as MutableRefObject<T>).current = node;
        return;
      }
      ref(node);
    });
  };
}

export function getNearestHalfTime(time: DateTime): DateTime {
  const newTime = time.startOf("minute");
  const minuteOffset = 30 - newTime.minute;
  return newTime.plus({ minute: minuteOffset });
}

export function setTime(date: DateTime, time: string) {
  const [hour, minute] = time.split(":");
  return date.set({ hour: +hour, minute: +minute }).startOf("minute");
}

export function getOfficeDay(workingDays: WorkingDays) {
  const officeDay = [];
  for (const workingDay in workingDays) {
    const day = workingDay as DayString;
    const enable = !!workingDays[day].startTime;
    const map: OfficeDay = {
      enable,
      day: formatDayString(day),
      timeRange: enable
        ? {
            start: fromISO(workingDays[day].startTime.toString()),
            end: fromISO(workingDays[day].endTime.toString()),
          }
        : null,
    };
    officeDay.push(map);
  }
  return officeDay;
}

export function transformTrainerProfile(
  trainerProfile: TrainerProfile | undefined
) {
  const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  const ignore = ["createdAt", "updatedAt"];
  if (!trainerProfile || trainerProfile?.deletedAt) return null;
  return Object.keys(trainerProfile).reduce(
    (memo, key) => {
      const currentDay = key.substring(0, 3);
      /* eslint-disable  @typescript-eslint/no-unsafe-assignment */
      const res = JSON.parse(JSON.stringify(memo));
      if (days.includes(currentDay)) {
        if (key.includes("Start"))
          /* eslint-disable  @typescript-eslint/no-unsafe-member-access */
          res["workingDays"][currentDay] = {
            /* eslint-disable  @typescript-eslint/no-unsafe-member-access */
            ...(res["workingDays"][currentDay] ?? {}),
            startTime: trainerProfile[key as keyof TrainerProfile],
          } as unknown;
        /* eslint-disable  @typescript-eslint/no-unsafe-member-access */ else
          res["workingDays"][currentDay] = {
            /* eslint-disable  @typescript-eslint/no-unsafe-member-access */
            ...(res["workingDays"][currentDay] ?? {}),
            endTime: trainerProfile[key as keyof TrainerProfile],
          } as unknown;
      } else if (!ignore.includes(key))
        /* eslint-disable  @typescript-eslint/no-unsafe-return */
        res[key] = trainerProfile[key as keyof TrainerProfile];
      return res;
    },
    { workingDays: {} } as Record<string, unknown>
  );
}

type GetTimeListInput = {
  start: DateTime;
  end: DateTime;
};

export function getTimeList(timeRange?: GetTimeListInput) {
  const hours = Array.from({ length: 24 }).map((_, index) => index);
  const minutes = ["00", "30"];

  const newHours = timeRange
    ? hours.filter(
        (hour) => hour >= timeRange.start.hour && hour <= timeRange.end.hour
      )
    : hours;

  const timeList = newHours.flatMap((hour) =>
    minutes.map((minute) => `${hour}:${minute}`)
  );

  if (timeRange?.start.minute === 30) {
    timeList.splice(0, 1);
  }

  if (timeRange?.end.minute === 0) {
    timeList.pop();
  }

  return timeList;
}

export function getAxiosErrorMessage(error: AxiosErrorWithData) {
  const isInternalServerError = error.response?.status === 500;
  const responseMessage = error.response?.data.message;

  return isInternalServerError || !responseMessage
    ? configs.unknownErrorMessage
    : responseMessage;
}

export const generateId = () => Math.random().toString(32);

export function useMounted() {
  const ref = useRef(true);

  useEffect(() => {
    ref.current = true;

    return () => void (ref.current = false);
  }, []);

  return ref;
}
