import { Box, Grid, Stack, Typography } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { formatDate, formatTimeRange, formatNumber } from "@/formatter";
import { cancelFacilityBooking } from "@/services/booking";
import { getAxiosErrorMessage, mapOptional } from "@/utils";

import type { FacilityBooking } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

export type CancelFacilityBookingDialogProps = {
  open: boolean;
  data?: FacilityBooking;
  onClose: () => void;
  fetchKeys: string[];
};

export function CancelFacilityBookingDialog({
  open: isOpen,
  data,
  onClose,
  fetchKeys = [],
}: CancelFacilityBookingDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { isActive } = useCurrentUser();
  const queryClient = useQueryClient();

  const { mutate: cancel, isLoading } = useMutation(cancelFacilityBooking, {
    onSuccess: async () => {
      enqueueSnackbar("ยกเลิกการจองสำเร็จ", { variant: "success" });
      await queryClient.refetchQueries(fetchKeys);
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getAxiosErrorMessage(error), { variant: "error" });
    },
  });

  if (!data) return <></>;

  const {
    id,
    startedAt,
    endedAt,
    facilities: { name, memberFee, guestFee },
    branchs,
  } = data;

  const hasFee = !!memberFee || !!guestFee;
  const fee = isActive ? memberFee : guestFee;
  const durationInMinute = endedAt.diff(startedAt, ["minutes"]).minutes;

  const onConfirm = () => {
    cancel(id);
  };

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title="คุณต้องการยกเลิกการจองหรือไม่"
      confirmMessage="ใช่"
      cancelMessage="ไม่ใช่"
      loading={isLoading}
      reverse={true}
    >
      <Stack>
        <Typography variant="body2">รายละเอียดการจอง</Typography>
        <Grid container alignItems="center">
          <DateRangeIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="body2" mb={3} mt={3}>
            {formatDate(startedAt)} {formatTimeRange(startedAt, endedAt)} (
            {durationInMinute}
            min)
          </Typography>
        </Grid>
        <Box
          display="grid"
          gridTemplateColumns="100px 1fr"
          rowGap={2}
          columnGap={3}
        >
          <Typography variant="subtitle2" color="text.disabled">
            สิ่งอำนวยความสะดวก
          </Typography>
          <Typography noWrap variant="body2">
            {name}
          </Typography>

          <Typography variant="subtitle2" color="text.disabled">
            สาขา
          </Typography>
          <Typography variant="body2">{branchs.name}</Typography>
          {hasFee && (
            <>
              <Grid item xs={3}>
                <Typography variant="subtitle2" color="text.disabled">
                  ค่าบริการเพิ่มเติม
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">
                  {mapOptional(fee, formatNumber) ?? "-"} บาท
                </Typography>
              </Grid>
            </>
          )}
        </Box>
      </Stack>
    </ConfirmDialog>
  );
}
