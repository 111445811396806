import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import liff from "@line/liff";

import { FormTextField } from "@/components/FormTextField";
import { FitUPLogo } from "@/components/FitUPLogo";
import { configs } from "@/configs";

import { ForgotPasswordDialog } from "../components/ForgotPasswordDialog";
import { signIn, useAuthentication } from "../contexts/AuthenticationContext";
import LoginBG from "../../../assets/login-bg.webp";
import LoginBGMobile from "../../../assets/login-bg-mobile.webp";

import type { InferType } from "yup";
import type { Control } from "react-hook-form";

type SignInEditorState = InferType<typeof schema>;

const defaultValues = {
  email: "",
  password: "",
};

const schema = yup.object({
  email: yup.string().label("อีเมล").email().required(),
  password: yup.string().label("รหัสผ่าน").required().min(6),
});

const resolver = yupResolver(schema);

function useSignInPage() {
  const { control, handleSubmit } = useForm({
    resolver,
    defaultValues,
  });
  const [{ loading, error }, dispatch] = useAuthentication();

  const submit = handleSubmit((form) =>
    signIn(dispatch, form.email, form.password)
  );

  return {
    control,
    submit,
    loading,
    error,
    dispatch,
  };
}

export function SignInPage() {
  const { control, submit, loading } = useSignInPage();
  const [searchParams] = useSearchParams();
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [isForgotPasswordDialogOpen, setIsForgotPasswordDialogOpen] =
    useState(false);

  useEffect(() => {
    async function checkLogin() {
      await liff.init({ liffId: configs.liffId });
      const fitnessCenterId = searchParams.get("fitnessCenterId") ?? "null";
      if (!liff.isLoggedIn()) {
        liff.login({
          redirectUri: `${
            import.meta.env.VITE_DEBUG
              ? "http://localhost:3000"
              : configs.liffUrl
          }/auth/sign-in?fitnessCenterId=${fitnessCenterId}`,
        });
        return;
      }
      const profile = await liff.getProfile();
      localStorage.setItem("userId", profile.userId);
      setUserId(profile.userId);
    }
    if (!userId) {
      void checkLogin();
    }
  }, [searchParams, userId]);

  const handleCloseForgotPasswordDialog = () =>
    setIsForgotPasswordDialogOpen(false);

  const handleOpenForgotPasswordDialog = () =>
    setIsForgotPasswordDialogOpen(true);

  return userId ? (
    <Stack
      width="100vw"
      minHeight="100vh"
      sx={{
        backgroundImage: {
          md: `url(${LoginBG})`,
          xs: `url(${LoginBGMobile})`,
        },
        backgroundPosition: "right top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        px: { xs: 3 },
      }}
      alignItems="center"
      justifyContent="center"
      spacing={4}
    >
      <Card sx={{ width: { md: "444px", xs: "100%" }, maxWidth: "444px" }}>
        <form onSubmit={submit} noValidate>
          <Stack component={CardContent} gap={2.5}>
            <FitUPLogo
              my={{ xs: 3, md: 7.5 }}
              color="#DE2826"
              alignSelf="center"
            />
            <Typography variant="h6" alignSelf="center" my={0.5}>
              เข้าสู่ระบบ
            </Typography>
            <FormTextField
              name="email"
              label="อีเมล"
              control={control}
              autoComplete="email"
            />
            <FormPasswordTextField control={control} />
            <Button
              color="inherit"
              sx={{ alignSelf: "flex-end" }}
              disableRipple
              onClick={handleOpenForgotPasswordDialog}
            >
              ลืมรหัสผ่าน
            </Button>
          </Stack>
          <CardActions
            sx={{ justifyContent: "center", p: 2, mt: { xs: 2, md: 5 }, mb: 2 }}
          >
            <LoadingButton type="submit" variant="contained" loading={loading}>
              เข้าสู่ระบบ
            </LoadingButton>
          </CardActions>
        </form>
      </Card>
      <Stack direction="row" alignItems="center">
        <FitUPLogo color="#DE2826" height="20px" />
        <Typography variant="subtitle1">Powered by FitUP</Typography>
      </Stack>
      <ForgotPasswordDialog
        open={isForgotPasswordDialogOpen}
        onClose={handleCloseForgotPasswordDialog}
      />
    </Stack>
  ) : (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
    >
      <CircularProgress />
    </Box>
  );
}

function FormPasswordTextField({
  control,
}: {
  control: Control<SignInEditorState>;
}) {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const password = useWatch({ name: "password", control });
  const isShowIcon = !!password;

  useEffect(() => {
    if (!password && isShowPassword) {
      setIsShowPassword(false);
    }
  }, [isShowPassword, password]);

  function togglePasswordVisibility() {
    setIsShowPassword(!isShowPassword);
  }

  const visibilityIcon = isShowPassword ? <VisibilityOff /> : <Visibility />;
  const passwordType = isShowPassword ? "text" : "password";

  return (
    <FormTextField
      name="password"
      type={passwordType}
      label="รหัสผ่าน"
      control={control}
      autoComplete="current-password"
      InputProps={{
        endAdornment: isShowIcon ? (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={togglePasswordVisibility}>
              {visibilityIcon}
            </IconButton>
          </InputAdornment>
        ) : (
          <></>
        ),
      }}
    />
  );
}
