import { Branch } from "@/models";
import { api } from "@/client/api";
import { configs } from "@/configs";

export async function getBranchesByClass(classId: number): Promise<Branch[]> {
  const response = await api.get(
    `${configs.apiUrl}/member/classes/${classId}/branches`
  );
  return response.data as Branch[];
}
