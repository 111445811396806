import { Stack, Typography } from "@mui/material";

import type { ComponentType } from "react";

export type EmptyResultIconVariant = "search" | "list" | "schedule";

const variants: Record<
  EmptyResultIconVariant,
  { Icon: ComponentType; message: string }
> = {
  search: {
    Icon: SearchNotFoundIcon,
    message: "ไม่พบข้อมูล",
  },
  list: {
    Icon: NoResultIcon,
    message: "ไม่มีข้อมูล",
  },
  schedule: {
    Icon: ScheduleNotFound,
    message: "ไม่มีตารางเวลา",
  },
};

export type EmptyResultProps = {
  variant?: EmptyResultIconVariant;
};

export function EmptyResultIcon({ variant = "list" }: EmptyResultProps) {
  const { Icon, message } = variants[variant];

  return (
    <Stack justifyContent="center" alignItems="center" gap={2} my={3}>
      <Icon />
      <Typography variant="subtitle1" color="text.disabled">
        {message}
      </Typography>
    </Stack>
  );
}

function NoResultIcon() {
  return (
    <svg
      width={200}
      height={200}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.617 90.735s27.693-1.109 33.678-46.631c5.314-40.428 52.013-22.027 65.511-5.911 17.487 20.876 8.165 56.788 30.786 61.325 22.621 4.536 7.849 54.977-26.488 50.149-42.714-6.006-32.38 18.954-45.203 29.518-9.202 7.582-46.936.284-48.193-27.24-1.059-23.161-10.828-23.088-17.753-25.714-9.988-3.788-16.28-31.219 7.662-35.496Z"
        fill="#F5F5F5"
      />
      <path d="M116.25 65.37v17.84h15.67l-15.67-17.84Z" fill="#9E9E9E" />
      <path
        d="M109.15 91.292V63h-35.5c-2.825 0-5.534 1.278-7.53 3.551C64.121 68.825 63 71.91 63 75.125V160h71V91.292h-24.85Z"
        fill="#9E9E9E"
      />
    </svg>
  );
}

function SearchNotFoundIcon() {
  return (
    <svg
      width={200}
      height={200}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.617 90.735s27.693-1.109 33.678-46.631c5.314-40.428 52.013-22.027 65.511-5.911 17.487 20.876 8.165 56.788 30.786 61.325 22.621 4.536 7.849 54.977-26.488 50.149-42.714-6.006-32.38 18.954-45.202 29.518-9.203 7.582-46.937.284-48.194-27.24-1.058-23.161-10.828-23.088-17.753-25.714-9.988-3.788-16.28-31.219 7.662-35.496Z"
        fill="#F5F5F5"
      />
      <path
        d="m131.759 141.29-27.208-26.885-5.388 5.453 27.208 26.885 5.388-5.453Z"
        fill="#E0E0E0"
      />
      <path
        d="m142.152 148.515-21.168-20.917a.3.3 0 0 0-.424.002l-8.012 8.108a.301.301 0 0 0 .003.425l21.168 20.916a.3.3 0 0 0 .424-.002l8.012-8.108a.3.3 0 0 0-.003-.424ZM80.436 127.927c18.718 0 33.893-15.175 33.893-33.893S99.155 60.142 80.436 60.142c-18.718 0-33.892 15.174-33.892 33.892 0 18.718 15.174 33.893 33.892 33.893Z"
        fill="#9E9E9E"
      />
      <path
        d="M80.436 122.396c15.664 0 28.362-12.698 28.362-28.362 0-15.664-12.698-28.362-28.362-28.362-15.664 0-28.362 12.698-28.362 28.362 0 15.664 12.698 28.362 28.362 28.362Z"
        fill="#F5F5F5"
      />
    </svg>
  );
}

function ScheduleNotFound() {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M167.504 84.1083C167.504 84.1083 142.871 98.1902 158.927 142.367C173.185 181.601 122.077 186.932 102.207 178.665C76.4665 167.957 67.9797 130.934 45.2897 137.493C22.5997 144.051 12.2048 91.258 45.6757 79.4327C87.3123 64.7227 66.1413 46.9279 72.8012 31.2778C77.5812 20.0456 115.304 8.86154 129.44 33.2703C141.336 53.8103 150.176 49.1303 157.707 48.2471C168.567 46.9728 187.234 68.9199 167.504 84.1083Z"
        fill="#F5F5F5"
      />
      <path
        d="M89.4642 119.583L99.0208 110.027L108.577 119.583L112.729 115.432L103.173 105.875L112.729 96.3183L108.577 92.1666L99.0208 101.723L89.4642 92.1666L85.3125 96.3183L94.8692 105.875L85.3125 115.432L89.4642 119.583ZM127.417 64.75H123.5V56.9166H115.667V64.75H84.3333V56.9166H76.5V64.75H72.5833C68.2358 64.75 64.7892 68.275 64.7892 72.5833L64.75 127.417C64.75 131.725 68.2358 135.25 72.5833 135.25H127.417C131.725 135.25 135.25 131.725 135.25 127.417V72.5833C135.25 68.275 131.725 64.75 127.417 64.75ZM127.417 127.417H72.5833V84.3333H127.417V127.417Z"
        fill="black"
        fillOpacity="0.28"
      />
    </svg>
  );
}
