import { Box, CardContent, Grid, Stack, Typography } from "@mui/material";

import { formatDate, formatProfile, formatTimeRange } from "@/formatter";
import { BookingButton } from "@/components/BookingButton";

import type { Schedule } from "@/models";
import type { MouseEventHandler } from "react";

export type ClassScheduleProps = {
  data: Schedule;
  setDialogProps: ({
    dialog,
    scheduleId,
  }: {
    dialog: string;
    scheduleId: string;
  }) => void;
};

export function ClassSchedule({ data, setDialogProps }: ClassScheduleProps) {
  const { startedAt, endedAt, staffs, location, capacity, bookings } = data;

  const activeBookings = bookings.filter(({ cancelledAt }) => !cancelledAt);
  const waitingBookings = bookings.filter(({ isWaiting }) => isWaiting);

  const onClickBooking: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setDialogProps({ dialog: "class-booking", scheduleId: data.id.toString() });
  };

  const bookingButtonProps = {
    data,
    sx: { maxWidth: 146, ml: { md: "auto" } },
    TypographySx: {
      maxWidth: { md: 146 },
      ml: { md: "auto" },
      textAlign: "center",
    },
    onClick: onClickBooking,
  };

  const waitingData = waitingBookings.length
    ? [{ label: "รอคิว", values: [`${waitingBookings.length} คน`] }]
    : [];

  const items = [
    {
      label: "ผู้สอน",
      values: staffs?.map(({ profile }) => formatProfile(profile)) || ["-"],
    },
    { label: "สถานที่", values: [location] },
    {
      label: "ผู้เข้าร่วม",
      values: [
        `${
          activeBookings.length >= capacity ? capacity : activeBookings.length
        }/${capacity} คน`,
      ],
    },
    ...waitingData,
  ];

  return (
    <CardContent sx={{ p: 0 }}>
      <Grid container columnSpacing={{ md: 5 }} rowSpacing={{ xs: 1.5, md: 0 }}>
        <Grid item md={3}>
          <Stack
            direction={{ xs: "row", md: "column" }}
            gap={{ xs: 3, md: 0.5 }}
            alignItems={{ xs: "center", md: "flex-start" }}
          >
            <Typography variant="h6">{formatDate(startedAt)}</Typography>
            <Typography variant="body1" color="text.disabled">
              {formatTimeRange(startedAt, endedAt)}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          md={6}
          pr={2}
          textAlign="left"
          sx={{
            borderLeft: { md: "solid 1px lightgray", xs: "none" },
            pl: { xs: 0, md: 10 },
            width: "100%",
          }}
        >
          <Stack gap={1}>
            {items.map(({ label, values }) => (
              <Box
                key={`${label} ${values.join(",")}`}
                display="grid"
                gridTemplateColumns="80px 1fr"
              >
                <Typography variant="subtitle2" color="text.disabled">
                  {label}
                </Typography>
                <Stack gap={1}>
                  {values.map((value, index) => (
                    <Typography key={`${value} ${index}`} variant="body2">
                      {value}
                    </Typography>
                  ))}
                </Stack>
              </Box>
            ))}
          </Stack>
        </Grid>
        <Grid item md={3} xs={12} mt={{ xs: 1.5, md: 0 }}>
          <BookingButton {...bookingButtonProps} />
        </Grid>
      </Grid>
    </CardContent>
  );
}
