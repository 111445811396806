import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useController, useForm, useWatch } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useSearchParams } from "react-router-dom";

import { FormDatePicker } from "@/components/DatePicker";
import { FormTimeSelect } from "@/components/TimeSelect";
import { FormSelect, Select } from "@/components/Select";
import { datetime } from "@/lib/yup";
import { now } from "@/lib/dateTime";
import { formatTime } from "@/formatter";
import { getNearestHalfTime, setTime } from "@/utils";
import { getAvailableTrainers } from "@/services/trainer";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { theme } from "@/theme";

import { TrainerBookingDialog } from "./TrainerBookingDialog";

import type { Control } from "react-hook-form";
import type { DayString, Trainer } from "@/models";
import type { InferType } from "yup";
import type { SelectChangeEvent } from "@mui/material";

const QUERY_KEY = "availableTrainer";

type TrainerBookingCardProps = {
  control: Control<TrainerBookingCardState>;
  data?: Trainer;
};

export function TrainerBookingCard({ control, data }: TrainerBookingCardProps) {
  const [dialog, setDialog] = useState("");
  const date = useWatch({ name: "date", control }) ?? now();
  const startTime = useWatch({ name: "startTime", control }) ?? "00:00";
  const hour = useWatch({ name: "hour", control });

  const { homeBranchId } = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();

  const branchId = searchParams.get("branchId") ?? homeBranchId.toString();

  const {
    field: { onChange },
  } = useController({ name: "endTime", control });
  const {
    field: { onChange: onChangeStart },
  } = useController({ name: "startTime", control });

  const start = setTime(date, startTime);
  const end = start.plus({ hour });

  useEffect(() => {
    onChange(formatTime(end));
  }, [onChange, end]);

  const { data: raw, isFetching } = useQuery(
    [QUERY_KEY, start.toISO(), end.toISO()],
    () => getAvailableTrainers({ start, end })
  );

  const selectedDay = (date ?? now())
    .toFormat("ccc")
    .toLowerCase() as DayString;
  const timeRange = data?.trainerProfileData.workingDays[selectedDay];

  const isWorkingDay = !!timeRange?.startTime;
  const isToday = now().toISODate() === date.toISODate();
  const isShiftTime =
    isToday &&
    isWorkingDay &&
    now().toFormat("HH:mm") > timeRange.startTime.toFormat("HH:mm");

  const optionsTimeRange = isWorkingDay
    ? {
        start: isShiftTime ? getNearestHalfTime(now()) : timeRange.startTime,
        end: timeRange.endTime.minus({ hour }),
      }
    : undefined;

  useEffect(() => {
    if (timeRange?.startTime) {
      onChangeStart(
        formatTime(
          isShiftTime ? getNearestHalfTime(now()) : timeRange.startTime
        )
      );
    }
  }, [onChangeStart, timeRange, date, hour, isShiftTime]);

  if (!data) return <></>;

  const { id, branches } = data;

  const isAvailable =
    isFetching || (raw?.map(({ id }) => id).includes(id) ?? true);

  const openDialog = () => setDialog("trainer-booking");
  const onCloseDialog = () => setDialog("");

  const bookingDialog = {
    open: dialog === "trainer-booking",
    data,
    start,
    end,
    queryKey: [QUERY_KEY],
    onClose: onCloseDialog,
    selectedBranch: {
      id: +branchId,
      name: branches.find(({ id }) => id === +branchId)?.name ?? "-",
    },
  };

  const isShowBranchSelect = branches.length > 1;

  const onChangeBranch = (event: SelectChangeEvent<number>) => {
    searchParams.set("branchId", event.target.value.toString());
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <Card sx={{ mt: { xs: -4, md: -5 } }}>
      <CardContent
        sx={{ px: { xs: 2, md: 5 }, py: { xs: 3, md: "40px!important" } }}
      >
        <Grid
          container
          columnSpacing={{ xs: 1, md: 3 }}
          rowSpacing={{ xs: 3, md: 2 }}
          columns={16}
        >
          {isShowBranchSelect && (
            <Grid item xs={16}>
              <Select
                label="สาขา"
                name="branch"
                value={+branchId}
                onChange={onChangeBranch}
                FormControlProps={{ fullWidth: true }}
              >
                {branches
                  ?.sort(
                    (a, b) => a.createdAt.toMillis() - b.createdAt.toMillis()
                  )
                  .map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          )}
          <Grid item xs={16} md={3} order={1}>
            <FormDatePicker
              name="date"
              control={control}
              label="วันที่"
              disablePast
              TextFieldProps={{ fullWidth: true }}
            />
          </Grid>
          <Grid item xs={16} md={7} order={2}>
            <Stack direction="row" gap={{ xs: 1, md: 3 }} width="100%">
              <FormTimeSelect
                label="เวลาเริ่ม"
                name="startTime"
                control={control}
                FormControlProps={{ fullWidth: true }}
                optionsProps={optionsTimeRange}
                disabled={!isWorkingDay}
              />
              <Typography variant="h6" mt={1.75}>
                -
              </Typography>
              <FormTimeSelect
                label="เวลาสิ้นสุด"
                name="endTime"
                control={control}
                disabled
                FormControlProps={{ fullWidth: true }}
              />
            </Stack>
          </Grid>
          <Grid item xs={16} md={3} order={3}>
            <FormSelect
              label="ระยะเวลา"
              name="hour"
              control={control}
              FormControlProps={{ fullWidth: true }}
            >
              <MenuItem value={1}>1 ชั่วโมง</MenuItem>
              <MenuItem value={2}>2 ชั่วโมง</MenuItem>
              <MenuItem value={3}>3 ชั่วโมง</MenuItem>
            </FormSelect>
          </Grid>
          <Grid
            item
            xs={16}
            md={3}
            order={5}
            textAlign="center"
            alignSelf="center"
          >
            <Button
              variant="contained"
              fullWidth
              onClick={openDialog}
              disabled={!isAvailable}
              sx={{ width: { xs: "50%", md: "100%" } }}
            >
              จอง
            </Button>
          </Grid>
          {!isAvailable && (
            <Grid item xs={16} md={16} order={{ xs: 4, md: 6 }}>
              <Typography
                color={`${theme.palette.error.main}!important`}
                sx={{ typography: { xs: "caption", md: "body1" } }}
              >
                ไม่สามารถทำการจองได้
                เนื่องจากช่วงเวลานี้ได้ถูกจองไปแล้วหรืออยู่นอกเวลาปฏิบัติงานของเทรนเนอร์
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <TrainerBookingDialog {...bookingDialog} />
    </Card>
  );
}

const schema = yup.object({
  date: datetime(),
  startTime: yup.string(),
  endTime: yup.string(),
  hour: yup.number(),
});

type TrainerBookingCardState = InferType<typeof schema>;

const resolver = yupResolver(schema);

const defaultValues = {
  date: now().startOf("day"),
  startTime: formatTime(getNearestHalfTime(now())),
  endTime: formatTime(getNearestHalfTime(now().plus({ hour: 1 }))),
  hour: 1,
} as TrainerBookingCardState;

export function useTrainerBookingCardForm() {
  return useForm({ resolver, defaultValues });
}
