import { Grid, Stack, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Fragment } from "react";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { formatDate, formatTimeRange } from "@/formatter";
import { createClassBooking } from "@/services/class";
import { getAxiosErrorMessage } from "@/utils";

import type { AxiosErrorWithData } from "@/client/api";
import type { Schedule } from "@/models";

export type BookingDialogProps = {
  open: boolean;
  data?: Schedule;
  onClose: () => void;
};

export function BookingDialog({
  open: isOpen,
  data,
  onClose,
}: BookingDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(createClassBooking, {
    onSuccess: async () => {
      enqueueSnackbar("การจองของคุณสำเร็จ", { variant: "success" });
      await queryClient.refetchQueries(["schedules"]);
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getAxiosErrorMessage(error), { variant: "error" });
    },
  });

  if (!data) return <></>;

  const {
    id,
    startedAt,
    endedAt,
    staffs,
    location,
    capacity,
    bookings,
    cancelBefore,
    class: fitnessClass,
    branch: { name: branchName },
  } = data;
  const className = fitnessClass?.name ?? "";

  const durationInMinute = endedAt.diff(startedAt, ["minutes"]).minutes;
  const cancelBeforeTime = startedAt.diff(cancelBefore, ["hours"]).hours;

  const onConfirm = () => {
    add({ scheduleId: id });
  };

  const activeBookings = bookings.filter(({ cancelledAt }) => !cancelledAt);
  const waitingBookings = bookings.filter(({ isWaiting }) => isWaiting);

  const activeCount =
    activeBookings.length >= capacity ? capacity : activeBookings.length;

  const waitingData = waitingBookings.length
    ? [{ label: "รอคิว", values: [`${waitingBookings.length} คน`] }]
    : [];

  const items = [
    { label: "ชื่อคลาส", values: [className] },
    {
      label: "ผู้สอน",
      values:
        staffs?.map(
          ({ profile }) =>
            `${profile.firstName} ${profile.lastName} (${profile.nickname})`
        ) ?? [],
    },
    { label: "สาขา", values: [branchName] },
    { label: "สถานที่", values: [location] },
    { label: "ผู้เข้าร่วม", values: [`${activeCount} / ${capacity} คน`] },
    ...waitingData,
    {
      label: "ยกเลิกการจอง\n(ก่อนเริ่มคลาส)",
      values: [`${cancelBeforeTime} ชั่วโมง`],
    },
  ];

  const isFullyBooked = bookings.length >= capacity;

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title="ยืนยันการจอง"
      confirmMessage="ยืนยัน"
      loading={isLoading}
    >
      <Stack>
        <Typography variant="body2">รายละเอียดการจอง</Typography>
        <Grid container alignItems="center">
          <DateRangeIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="body2" mb={3} mt={3}>
            {formatDate(startedAt)} {formatTimeRange(startedAt, endedAt)} (
            {durationInMinute}
            min)
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          {items.map(({ label, values }) => (
            <Fragment key={label}>
              <Grid item xs={4}>
                <Typography
                  variant="subtitle2"
                  color="text.disabled"
                  whiteSpace="pre-line"
                >
                  {label}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {values.map((value, index) => (
                  <Typography key={`${value} ${index}`} variant="body2">
                    {value}
                  </Typography>
                ))}
              </Grid>
            </Fragment>
          ))}
          {isFullyBooked && (
            <>
              <Grid item xs={2}>
                <ErrorIcon color="error" />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body2" color="error.main">
                  ขณะนี้มีผู้เข้าร่วมเข้าครบเต็มจำนวนแล้ว หากคุณจองจะอยู่ใน
                  รายการรอคิว และถ้าหาก มีผู้เข้าร่วมยกเลิก จะเรียกคิวตามลำดับ
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
    </ConfirmDialog>
  );
}
