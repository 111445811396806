import {
  Button,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import {
  formatBookingStatus,
  formatBookingStatusColor,
  formatDate,
  formatTimeRange,
} from "@/formatter";
import { getBookingStatus } from "@/utils";
import { theme } from "@/theme";
import { BookingStatus } from "@/models";
import { now } from "@/lib/dateTime";

import type { Booking } from "@/models";
import type { MouseEventHandler } from "react";

export type BookingProps = {
  data: Booking;
  isHistory?: boolean;
  setDialogProps?: ({ dialog, id }: { dialog: string; id: string }) => void;
};

export function BookingCard(props: BookingProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { data, isHistory, setDialogProps } = props;

  const {
    startedAt,
    endedAt,
    scheduleTrainers: staffs,
    location,
    capacity,
    bookings,
    class: classModel,
    branch,
  } = data.schedule;

  const activeBookings = bookings.filter(({ cancelledAt }) => !cancelledAt);
  const waitingBookings = bookings.filter(({ isWaiting }) => isWaiting);

  const bookedCount =
    activeBookings.length >= capacity ? capacity : activeBookings.length;

  const onClickDetail: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    if (classModel) {
      setDialogProps?.({
        dialog: "class-detail",
        id: classModel.id.toString(),
      });
    } else {
      setDialogProps?.({ dialog: "trainer-detail", id: data.id.toString() });
    }
  };

  const status = getBookingStatus(data);
  const isTrainerBookingEnd =
    isHistory &&
    !classModel &&
    [BookingStatus.Booked, BookingStatus.Absent].includes(status);

  return (
    <CardContent>
      <Grid container gap={2} mt={4} mb={4}>
        <Grid
          item
          md={2}
          sx={{
            flexDirection: { xs: "row", md: "column" },
            display: { xs: "flex", md: "block" },
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <>
            <Typography variant="h6">{formatDate(startedAt)}</Typography>
            <Typography
              variant="body1"
              color="text.disabled"
              sx={{
                marginTop: { xs: 0.5, md: 0 },
                marginLeft: { xs: 2, md: 0 },
              }}
            >
              {formatTimeRange(startedAt, endedAt)}
            </Typography>
          </>

          {!isHistory && isMobile && <CancelButton {...props} />}
        </Grid>
        <Grid
          item
          md={7}
          pr={2}
          textAlign="left"
          sx={{
            borderLeft: { md: "solid 1px lightgray", xs: "none" },
            pl: { xs: 0, md: 10 },
            width: "100%",
          }}
        >
          <Typography
            noWrap
            variant="h6"
            width={{ xs: "calc(100vw - 80px)", md: "unset" }}
          >
            {classModel?.name ??
              `${staffs?.[0].staff.profile.firstName ?? ""} ${
                staffs?.[0].staff.profile.lastName ?? ""
              }`}
          </Typography>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={3}>
              <Typography variant="subtitle2" color="text.disabled">
                ประเภท
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">
                {classModel ? "คลาส" : "เทรนเนอร์"}
              </Typography>
            </Grid>
            {classModel && (
              <>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" color="text.disabled">
                    ผู้สอน
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {staffs?.map((traniner) => (
                    <Typography key={traniner.staff.id} variant="body2">
                      {traniner.staff.profile.firstName}{" "}
                      {traniner.staff.profile.lastName} (
                      {traniner.staff.profile.nickname})
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" color="text.disabled">
                    สถานที่
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{location}</Typography>
                </Grid>
              </>
            )}
            <Grid item xs={3}>
              <Typography variant="subtitle2" color="text.disabled">
                สาขา
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{branch.name}</Typography>
            </Grid>
            {classModel && (
              <>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" color="text.disabled">
                    ผู้เข้าร่วม
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">
                    {bookedCount} / {capacity} คน
                  </Typography>
                </Grid>
                {!!waitingBookings.length && (
                  <>
                    <Grid item xs={3}>
                      <Typography variant="subtitle2" color="text.disabled">
                        รอคิว
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2">
                        {waitingBookings.length} คน
                      </Typography>
                    </Grid>
                  </>
                )}
              </>
            )}
            <Grid item xs={3}>
              <Typography variant="subtitle2" color="text.disabled">
                สถานะ
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body2"
                color={
                  isTrainerBookingEnd
                    ? "text.secondary"
                    : formatBookingStatusColor(status)
                }
              >
                {isTrainerBookingEnd
                  ? "สิ้นสุดแล้ว"
                  : formatBookingStatus(status)}
              </Typography>
            </Grid>
          </Grid>
          {!isHistory && (
            <Button
              color="inherit"
              sx={{ mt: 2.5, p: 0, width: { xs: "100%", md: "auto" } }}
              onClick={onClickDetail}
            >
              ดูรายละเอียดเพิ่มเติม {">"}
            </Button>
          )}
        </Grid>
        {!isHistory && !isMobile && (
          <Grid
            item
            md={2}
            xs={12}
            sx={{
              pl: { xs: "20%", md: 0 },
              pr: { xs: "20%", md: 0 },
            }}
          >
            <CancelButton {...props} />
          </Grid>
        )}
      </Grid>
    </CardContent>
  );
}

function CancelButton({
  data: { id, schedule, cancelledAt },
  setDialogProps,
}: BookingProps) {
  const CANCEL_TRAINER_BEFORE_HOURS = 24;

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    startedAt,
    class: fitnessClass,
    cancelBefore: cancelClassBefore,
  } = schedule;

  const cancelTrainerBefore = startedAt.minus({
    hour: CANCEL_TRAINER_BEFORE_HOURS,
  });

  const cancelBefore = fitnessClass ? cancelClassBefore : cancelTrainerBefore;
  const isCancelTime = cancelBefore >= now();

  const disabled = !!cancelledAt || !isCancelTime;
  const onClickCancel: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setDialogProps?.({ dialog: "cancel-booking", id: id.toString() });
    searchParams.set("branchId", schedule.branch.id.toString());
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <Button
      color="error"
      variant="outlined"
      disabled={disabled}
      onClick={onClickCancel}
    >
      ยกเลิก
    </Button>
  );
}
