import { configs } from "@/configs";
import { getIdToken, signOut } from "@/lib/firebase/auth";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

const http = axios.create({
  baseURL: configs.apiUrl,
});
/* eslint-disable  @typescript-eslint/no-explicit-any */
const listeners: ((
  error: Error,
  request: AxiosRequestConfig & { _retry?: boolean }
) => any | PromiseLike<any>)[] = [];

http.interceptors.request.use(async (config) => {
  const headers = config.headers ?? {};
  const token = await getAuthToken();
  const branchId = localStorage.getItem("branchId");

  if (token) {
    config.headers = {
      ...headers,
      authorization: token,
      "x-branch-id": branchId ?? "",
    };
  }
  return config;
});

http.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if ((error.response?.data as { code: string }).code === "auth/expired") {
      await signOut();
    }
    throw error;
  }
);

type ErrorResponse = {
  error: string;
  message: string;
  statusCode: number;
};

export type AxiosErrorWithData = AxiosError<ErrorResponse>;

export const api = {
  async request<T = any>(...params: Parameters<typeof http["request"]>) {
    return http.request<T>(...params);
  },
  async get<T = any>(...params: Parameters<typeof http["get"]>) {
    return http.get<T>(...params);
  },
  async post<T = any>(...params: Parameters<typeof http["post"]>) {
    return http.post<T>(...params);
  },
  async put<T = any>(...params: Parameters<typeof http["put"]>) {
    return http.put<T>(...params);
  },
  async patch<T = any>(...params: Parameters<typeof http["patch"]>) {
    return http.patch<T>(...params);
  },
  async delete<T = any>(...params: Parameters<typeof http["delete"]>) {
    return http.delete<T>(...params);
  },
  onError(
    handler: (
      error: Error,
      request: AxiosRequestConfig & { _retry?: boolean }
    ) => void
  ): () => void {
    listeners.push(handler);

    return () => {
      const index = listeners.findIndex((h) => h === handler);

      if (index !== -1) listeners.splice(index, 1);
    };
  },
};

async function getAuthToken() {
  try {
    const token = await getIdToken();
    return token ? `Bearer ${token}` : null;
  } catch (error) {
    console.error("getAuthToken", error);
  }
}
